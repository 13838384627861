
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import PacientesService from "../../services/PacientesService";
import FamiliaresService from "../../services/FamiliaresService";
import InformacionPacientes from "../../services/InformacionPacientes";

import "../../styles/Familiares.css";

import img_edit from "../../img/edit_note.svg";
import img_delete from "../../img/delete.svg";
import img_search from "../../img/search_pax.svg";
import img_person from "../../img/person_add.svg";
import img_clear from "../../img/clear.svg";
import ParentescoService from "../../services/ParentescoService";

const _ = require('lodash');



const Familiares = (id) => {
    const [pacientes, setPacientes] = useState([]);
    const [famNoEsPac, setFamNoPaciente] = useState([]);
    const [famEsPac, setFamEsPaciente] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [selectedValue, setSelectedValue] = useState(""); // Estado para el valor del select
    const [inputValue, setInputValue] = useState("");
    const [esPaciente, setEsPaciente] = useState("");   // evaluar si el Familiar tambien es paciente
    const [fotografia, setFotografia] = useState('');
    const [sexo, setSexo] = useState('');
    const [edoCivil, setEdoCivil] = useState('');
    const [edo, setEstado] = useState('');
    const [pais, setPais] = useState('');
    const [foto, setFoto] = useState('');  // bandera, hay o no foto en la DB
    const [datosFNP, setDatosFNP] = useState(0);     // bandera fam NO pac, hay o no datos del familiar
    const [datosFP, setDatosFP] = useState(0);     // bandera fam ES pac, hay o no datos del familiar
    const [idFamiliar, setIdFamiliar] = useState(null);      // recibe el id_familiar de la vista: vFamNoEsPac. Para eliminar al familiar
    const [idParent, setIdParentesco] = useState(null);     // recibe el id_parentesco de las vistas: vFamNoEsPac y vFamEsPac. Para eliminar el parentesco del familiar-paciente
    const [nombreEliminar, setNombreEliminar] = useState("");    // recibe los nombres: paciente-familiar y familiar de vFamNoEsPac y vFamEsPac
    const [displayFam, setDisplayFam] = useState("0");   // para el mensaje: Selecciona un paciente de la lista


    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    function refreshPage() {
        window.location.reload(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }


    const navigate = useNavigate();

    const getData = async () => {
        if (routeParams.id !== undefined) {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        }
    };


    useEffect(() => {
        getData();
    }, []);


    const DisplayData = pacientes.map((info) => {
        return (
            <tr>
                <td>
                    <label>
                        <input type="radio" name="status" id="radio_check_fam" onClick={() => Familiar_Paciente(info.id_paciente)} />
                        <span>
                            {info.nombre} {info.ap1} {info.ap2}
                        </span>
                    </label>
                </td>
            </tr>
        )
    });


    // cargar datos familiares relcionados con un paciente   ---   idPaciente
    const Familiar_Paciente = async (idPaciente) => {
        const data = await FamiliaresService.getFamiliarNoEsPaciente(idPaciente);   //vFamNoEsPaciente
        setDatosFNP(data.length);   // 0: no hay datos,    >0: hay datos
        setFamNoPaciente(data);    //famNoEsPac
        setDisplayFam("1");

        const data2 = await FamiliaresService.getFamiliarEsPaciente(idPaciente);   //vFamEsPaciente
        setDatosFP(data2.length);   // 0: no hay datos,    >0: hay datos
        setFamEsPaciente(data2);    //famNoEsPac
        setDisplayFam("1");
    }



    // Display Familiar No es paciente
    const DisplayData2 = famNoEsPac.map((famN) => {
        return (
            <tr id="filas_familiar">
                <td>
                    <button type="button" className="btn btn-outline-secondary" onClick={() => cargarInformacionPorFamiliar(famN.id_familiar, famN.id_parentesco)}>
                        {famN.f_nom} {famN.f_ap1} {famN.f_ap2}
                    </button>
                </td>
                <td id="td_2_fam"> {famN.parentesco} </td>
                <td id="td_3_fam"> No </td>
            </tr>
        )
    })



    // cargar información del familiar
    const cargarInformacionPorFamiliar = async (id_familiar, id_parentesco) => {

        // DisplayData2: El familiar no es paciente

        setIdFamiliar(id_familiar);
        setIdParentesco(id_parentesco);

        const data = await FamiliaresService.getFamiliarIndividual(id_familiar);

        setNombreEliminar(data[0].nombre + " " + data[0].ap1 + " " + data[0].ap2);

        setSexo(InformacionPacientes.sexoPaciente(data[0].sexo));
        setEdoCivil(InformacionPacientes.estadoCivil(data[0].edo_civil));
        setEstado(InformacionPacientes.estadosRepublica(data[0].edo));
        setPais(InformacionPacientes.paisesPaciente(data[0].pais));

        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }

        setEsPaciente("no");
        setInformacion(data[0]);
        setFotografia(data[0].fotografia.data)  // get base64 de DB
    }



    // Display paciente_familiar: el familiar es paciente
    const DisplayData3 = famEsPac.map((famP) => {
        return (
            <tr id="filas_familiar">
                <td>
                    <button type="button" className="btn btn-outline-secondary" onClick={() => cargarInfoPacFamiliar(famP.id_paciente_familiar, famP.id_parentesco)}>
                        {famP.pf_nom} {famP.pf_ap1} {famP.pf_ap2}
                    </button>
                </td>
                <td id="td_2_fam"> {famP.parentesco} </td>
                <td id="td_3_fam"> Sí </td>
            </tr>
        )
    });

    // cargar información del paciente familiar
    const cargarInfoPacFamiliar = async (id_paciente, id_parentesco) => {

        setIdParentesco(id_parentesco);

        const data = await PacientesService.getInfoPaciente(id_paciente);

        setNombreEliminar(data[0].nombre + " " + data[0].ap1 + " " + data[0].ap2);

        setSexo(InformacionPacientes.sexoPaciente(data[0].sexo));
        setEdoCivil(InformacionPacientes.estadoCivil(data[0].edo_civil));
        setEstado(InformacionPacientes.estadosRepublica(data[0].edo));
        setPais(InformacionPacientes.paisesPaciente(data[0].pais));

        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }

        setEsPaciente("si");
        setInformacion(data[0]);
        setFotografia(data[0].fotografia.data)  // get base64 de DB
    };


    const eliminarFamiliar = async () => {

        Swal.fire({
            title: '¡Advertencia!',
            text: `Estás seguro de eliminar al familiar: ${nombreEliminar} \n ¿Deseas continuar?`,
            icon: 'warning',
            confirmButtonText: 'Aceptar',
            showCancelButton: true
        }).then(async (result) => {
            if (result.isConfirmed) {

                // primero, eliminar el parentesco
                const elimParent = await ParentescoService.DeleteParentesco(idParent);
                if (elimParent.status === 200) {

                    if (esPaciente === "no") {

                        // segundo, eliminar familiar
                        const elimFam = await FamiliaresService.DeleteFamiliar(idFamiliar);
                        if (elimFam.status === 200) {
                            Swal.fire({
                                title: "Familiar eliminado",
                                text: "El familiar se eliminó correctamente.",
                                showConfirmButton: true,
                                confirmButtonText: "Aceptar",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    refreshPage();
                                }
                            });
                        }
                    } else {    // si sí es paciente...
                        Swal.fire({
                            title: "Parentesco eliminado",
                            text: "El parentesco asociado al familiar, se eliminó correctamente.",
                            showConfirmButton: true,
                            confirmButtonText: "Aceptar",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                refreshPage();
                            }
                        });
                    }
                } else {
                    Toast.fire("Hubo un problema al eliminar el paciente.", "", "error");
                }
            }
        });
    }


    const editarFamiliar = async () => {
        if (esPaciente === 'no') {
            // local
            // navigate(`/editfamiliar/${informacion.id_familiar}`);

            // web
            navigate(`/editfamiliar/${informacion.id_familiar}`);

        } else if (esPaciente === 'si') {
            Swal.fire({
                title: "Editar paciente",
                text: "Estás tratando de editar un paciente...",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isConfirmed) {
                    // navigate(`/editpaciente/${informacion.id_paciente_familiar}`);  // local
                    navigate(`/editpaciente/${informacion.id_paciente_familiar}`);     // web
                }
            });
        }
    }

    const agregarFamiliar = () => {
        // navigate('/addfamiliar');   //local
        navigate('/addfamiliar');   //web
    }

    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value);
        setPacientes(data);
    }

    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value);
            setPacientes(data);
        }
    }

    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    return (
        <Layout>
            <Content>
                <div className="px_div_1_familiares">
                    <div className="px_div_2_fam">
                        <div className="px_sesion_fam">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_fam">
                            <label id="lbl_familiares">Familiares</label>
                        </div>
                    </div>
                    <div className="px_div_3_fam">
                        <div className="div_filtro_selec_px_fam">
                            <div className="px_filtro_fam">
                                <select id="px_select_fam" value={selectedValue} onChange={buscarFiltro}>
                                    <option value="" disabled >Selecciona un filtro</option>
                                    <option value="A">Activos</option>
                                    <option value="I">Inactivos</option>
                                </select>
                                <button id="btn-limpiar-filtro" onClick={limpiarFiltro}>
                                    <img src={img_clear} alt="img_clear" />
                                    Limpiar
                                </button>
                            </div>
                            <div className="px_input_fam">
                                <div className="input_buscar_fam">
                                    <input type="search" placeholder="Buscar paciente..." id="Buscador" value={inputValue} onChange={buscar} />
                                    <img src={img_search} alt="buscar" id="img_lupa"></img>
                                </div>
                            </div>
                            <div className="div_tablas_th_px-fam">
                                <table id="filas_px-fam">
                                    <tbody id="tbody_px-fam">
                                        {DisplayData}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="div_boton_tabla_fam">
                            <div className="px_nuevo_fam">
                                <button id="px_btn_nuevo" onClick={agregarFamiliar}>
                                    <img src={img_person} alt="img_person" />
                                    Familiar
                                </button>
                            </div>
                            <div className="div_tablas_th_fam">
                                <div id="div_table_encabezado_fam">
                                    <table id="encabezado_familiar">
                                        <tbody>
                                            <tr id="tr-encabezado-fam">
                                                <th>Familiares del paciente</th>
                                                <th>Parentesco</th>
                                                <th>Es paciente</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="div-table-display2">
                                    {
                                        displayFam === "0" ? (
                                            <div className="div-selec-px">
                                                <label id="label-selec-px-px"> Selecciona un paciente de la lista. </label>
                                            </div>
                                        ) : displayFam === "1" ? (
                                            datosFNP === 1 || datosFP === 1 ? (
                                                <table id="filas_familiar">
                                                    <tbody>
                                                        {DisplayData2}
                                                    </tbody>
                                                    <tbody>
                                                        {DisplayData3}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div className="div-selec-px">
                                                    <label id="label-selec-px-px"> No existen familiares relacionados. </label>
                                                </div>
                                            )
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="px_tabla_fam">
                            {informacion.length === 0 ? (
                                <div>
                                    <div id="edit_delete_fam">
                                        <div id="px_h3_fam">
                                            <label>Familiar</label>
                                        </div>
                                    </div>
                                    <div className="div-selec-px">
                                        <label id="label-selec-px-px"> Selecciona un familiar de la lista. </label>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div id="edit_delete_fam">
                                        <div id="px_h3_fam">
                                            <label>Familiar</label>
                                        </div>
                                        <div className="div-px-toolTip">
                                            <div id="container-toolTip-editar-px">
                                                <span className="lbl-toolTip-editar-px">
                                                    Editar
                                                </span>
                                                <img src={img_edit} alt="edit" id="img_edit" onClick={editarFamiliar} />
                                            </div>
                                            <div id="container-toolTip-delete-px">
                                                <span className="lbl-toolTip-delete-px">
                                                    Eliminar
                                                </span>
                                                <img src={img_delete} alt="delete" id="img_delete" onClick={eliminarFamiliar} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div-avatar-px">
                                        {
                                            foto === "1" ? (
                                                fotografia === "" ? (
                                                    <div id="img_avatar_px">
                                                        <label> Cargando imagen...</label>
                                                    </div>
                                                ) : (
                                                    <div id="img_avatar_px">
                                                        <img src={fotografia} alt="base64"></img>
                                                    </div>
                                                )
                                            ) : (<div></div>)
                                        }
                                    </div>
                                    <table id="table_px_seleccionado_fam">
                                        <tbody>
                                            <tr className="padding-row">
                                                <td id="td-paciente-fam" colSpan="2">
                                                    <center>
                                                        <label id="lbl-paciente-fam">
                                                            {informacion.nombre} {informacion.ap1} {informacion.ap2}
                                                        </label>
                                                    </center>
                                                </td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-edad" htmlFor="lbl-edad">Edad:</label></td>
                                                <td><label id="lbl-edad">{informacion.edad}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-sexo" htmlFor="lbl-sexo">Sexo:</label></td>
                                                <td><label id="lbl-sexo">{sexo}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-edo-civil" htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                <td><label id="lbl-edo-civil">{edoCivil}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-tel" htmlFor="lbl-tel">Teléfono:</label></td>
                                                <td><label id="lbl-tel">{informacion.tel}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-correo" htmlFor="lbl-correo">Correo:</label></td>
                                                <td><label id="lbl-correo">{informacion.correo}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-domicilio" htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                <td><label id="lbl-domicilio">{informacion.domicilio}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-ciudad" htmlFor="lbl-ciudad">Ciudad:</label></td>
                                                <td><label id="lbl-ciudad">{informacion.ciudad}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-cp" htmlFor="lbl-cp">Código Postal:</label></td>
                                                <td><label id="lbl-cp">{informacion.cod_pos}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-edo" htmlFor="lbl-edo">Estado:</label></td>
                                                <td><label id="lbl-edo">{edo}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-pais" htmlFor="lbl-pais">País:</label></td>
                                                <td><label id="lbl-pais">{pais}</label></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Familiares;
