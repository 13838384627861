
// -------- HeaderAdmin ---------------- //

import React from "react";
import { useNavigate } from "react-router-dom";

import "../styles/header.css";
import "../styles/MenuUsuario.css";

import logo from "../img/psiquedata_logo_trans.png";
import img_avatar from "../img/avatar.png";
import img_colegas from "../img/groups.svg";
import img_calendar from "../img/calendar_month_black.svg";
import img_patient from "../img/patient_list_black.svg";
import img_pending from "../img/pending_actions_black.svg";
import img_salir from "../img/logout_black.svg";



const HeaderAdmin = () => {

  const usuarioString = sessionStorage.getItem('usuario');
  const usuario = JSON.parse(usuarioString);

  const navigate = useNavigate();

  /*  Entorno web:   ligas href="#/pac" llevan símbolo #, por el método de rutas: HashRouter
      Los navigate(/pacientes) de rutas primarias, no llevan #, HashRouter antecede el símbolo #
  
    Entorno local: se puede omitir #
  
  */


  const cerrarSesion = () => {
    navigate("/");
  }

  const handleAgenda = () => {
    navigate("/calendario");
  }

  const handlePacientes = () => {
    navigate("/pacientes");
  }

  const handlePendientes = () => {
    navigate("/pendientes");
  }


  return (
    <div className="div-header">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <button className="btn btn-primary" type="button" onClick={handleAgenda}>
          <img id="img_calendar" src={img_calendar} alt="img_calendar"></img>
          Agenda
        </button>
        <button className="btn btn-primary" type="button" onClick={handlePacientes}>
          <img id="img_patient" src={img_patient} alt="img_patient"></img>
          Pacientes
        </button>
        <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          ☰ Módulos
        </button>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item" href="#/citas">Citas</a></li>
          <li><a className="dropdown-item" href="#/sesiones">Sesiones</a></li>
          <li><a className="dropdown-item" href="#/expedientes">Expedientes</a></li>
          <li><a className="dropdown-item" href="#/familiares">Familiares</a></li>
          <li><a className="dropdown-item" href="#canalizaciones">Canalizaciones</a></li>
          <li><hr className="dropdown-divider" /></li>
          <li><a className="dropdown-item" href="#/recomendaciones">Recomendaciones</a></li>
        </ul>
        <button className="btn btn-primary" type="button" onClick={handlePendientes}>
          <img id="img_pending" src={img_pending} alt="img_pending"></img>
          Pendientes
        </button>
        <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          Cuenta
        </button>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item" href={`#/psicologo/${usuario.id_psicologo}`}>
            <img id="img_profile" src={img_avatar} alt="img_profile"></img>
            {usuario.nombre} {usuario.ap1} {usuario.ap2}
          </a>
          </li>
          <li><a className="dropdown-item" href="#/colegas">
            <img id="img_colegas" src={img_colegas} alt="img_colegas"></img>
            Colegas
          </a>
          </li>
        </ul>
        <button className="btn btn-primary" type="button" onClick={cerrarSesion}>
          <img id="img_salir" src={img_salir} alt="img_salir"></img>
          Salir
        </button>
      </div>
    </div>
  )
};

export default HeaderAdmin;