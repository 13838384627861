
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import PacientesService from "../../services/PacientesService";
import FamiliaresService from "../../services/FamiliaresService";
import InformacionPacientes from "../../services/InformacionPacientes";
import ParentescoService from "../../services/ParentescoService";

import "../../styles/FormularioAddFamiliar.css";

import img_search from "../../img/search_pax.svg";
import img_clear from "../../img/clear.svg";


const AddFamiliar = () => {

    const [idselectpac, setIdSelectPac] = useState('');     // obtener Id pac seleccionado
    const [getFamiliar, setGetFamiliares] = useState([]);
    const [pacientes, setPacientes] = useState([]);

    const [inputBuscarFam, setInputBuscarFam] = useState('');
    const [esPac, setEsPac] = useState(null);
    const [famBuscar, setFamiliarBuscar] = useState(null);
    const [famEsPaciente, setFamEsPaciente] = useState([]);
    const [famNoEsPaciente, setFamNoEsPaciente] = useState([]);

    const [parentescoTabla, setParentescoTabla] = useState([]);

    // select Pac Activos, Inactivos
    const [selectedValue, setSelectedValue] = useState('');
    // seleccionar paciente que tiene relación con el familiar
    const [selectInicial, setSelectValueInicial] = useState('');
    // Estado para el valor del select Buscar Familiar
    const [selectedValueFam, setSelectedValueFam] = useState('');
    // Estado para el valor del select Buscar Pac
    const [selectedValuePac, setSelectedValuePac] = useState('');


    // datos tabla Familiar
    const [nombre, setNombre] = useState('');
    const [ap1, setAp1] = useState('');
    const [ap2, setAp2] = useState('');
    const [edad, setEdad] = useState(null);
    const [sexo, setSexo] = useState('');
    const [edoCivil, setEdoCivil] = useState('');
    const [tel, setTel] = useState('');
    const [correo, setCorreo] = useState('');
    const [domicilio, setDomicilio] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [codPostal, setCodPostal] = useState(null);
    const [estado, setEstado] = useState('');
    const [pais, setPais] = useState('');
    const [image, setImage] = useState('');

    // datos (si) el fam es paciente
    const [nomuser_p, setNomUser_p] = useState('');
    const [nombre_p, setNombre_p] = useState('');
    const [ap1_p, setAp1_p] = useState('');
    const [ap2_p, setAp2_p] = useState('');
    const [edad_p, setEdad_p] = useState('');
    const [sexo_p, setSexo_p] = useState('');
    const [edoCivil_p, setEdoCivil_p] = useState('');
    const [tel_p, setTel_p] = useState('');
    const [correo_p, setCorreo_p] = useState('');
    const [domicilio_p, setDomicilio_p] = useState('');
    const [ciudad_p, setCiudad_p] = useState('');
    const [codPostal_p, setCodpostal_p] = useState('');
    const [estado_p, setEstado_p] = useState('');
    const [pais_p, setPais_p] = useState('');
    const [escolaridad_p, setEscolaridad_p] = useState('');
    const [profesion_p, setProfesion_p] = useState('');
    const [trabajoActual_p, setTrabajoActual_p] = useState('');
    const [prefSexual_p, setPrefSexual_p] = useState('');

    const [fotografia_p, setFotografia_p] = useState('');
    const [foto, setFoto] = useState('');  // bandera, hay o no foto en la DB

    // handleParentesco Fam y Pac 
    const [parentescoFam, setParentescoFam] = useState('');
    const [parentescoPac, setParentescoPac] = useState('');


    const navigate = useNavigate();
    const usuarioString = sessionStorage.getItem('usuario');    //usuario psicólogo
    const usuario = JSON.parse(usuarioString);

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }


    const getData = async () => {
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);

        const data2 = await FamiliaresService.getFamiliares();
        setGetFamiliares(data2);

        const data3 = await ParentescoService.TableParentesco();
        setParentescoTabla(data3);
    }

    useEffect(() => {
        getData();
    }, []);


    // Para select, si el fam a ingresar NO es pac: radiobutton no
    const cargarInfoPacRelacionado = async (event) => {
        const data = await PacientesService.getInfoPaciente(event.target.value);
        setIdSelectPac(data[0].id_paciente);    // obtiene el id del paciente seleccionado del Select: Paciente relacionado
        familiarEsPaciente(data[0].id_paciente);
        familiarNoEsPaciente(data[0].id_paciente);
    };

    /*-------------------------*/

    const familiarEsPaciente = async (idpaciente) => {
        const data = await FamiliaresService.getFamiliarEsPaciente(idpaciente);
        setFamEsPaciente(data);
    }

    const familiarNoEsPaciente = async (idpaciente) => {
        const data = await FamiliaresService.getFamiliarNoEsPaciente(idpaciente);
        setFamNoEsPaciente(data);
    }

    /*-------------------------*/


    // Para select, si el fam a ingresar ES pac: radiobutton sí
    const cargarInfoPaciente = async (event) => {
        const data = await PacientesService.getInfoPaciente(event.target.value);

        setSelectedValuePac(data[0].id_paciente);

        setSexo_p(InformacionPacientes.sexoPaciente(data[0].sexo));
        setEdoCivil_p(InformacionPacientes.estadoCivil(data[0].edo_civil));
        setEstado_p(InformacionPacientes.estadosRepublica(data[0].edo));
        setPais_p(InformacionPacientes.paisesPaciente(data[0].pais));

        const cont = data[0].fotografia.data;       // lenght 40, cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");  // bandera: No hay foto en la DB
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {  // existe foto en la DB
            setFoto("1");    // bandera: Sí hay foto en la DB
            setFotografia_p(data[0].fotografia.data)  // get base64 de DB
        }

        setNomUser_p(data[0].nom_usuario);
        setNombre_p(data[0].nombre);
        setAp1_p(data[0].ap1);
        setAp2_p(data[0].ap2);
        setEdad_p(data[0].edad);
        setEscolaridad_p(data[0].escolaridad);
        setProfesion_p(data[0].profesion);
        setTrabajoActual_p(data[0].trabajo_actual);
        setPrefSexual_p(data[0].pref_sexual);
        setTel_p(data[0].tel);
        setCorreo_p(data[0].correo);
        setDomicilio_p(data[0].domicilio);
        setCodpostal_p(data[0].cod_pos);
        setCiudad_p(data[0].ciudad);

    };



    /*-------------------------*/


    const displayFamEsPac = famEsPaciente.map((fam) => {
        return (
            <tr id="displayPacRel">
                <td>{fam.pf_nom} {fam.pf_ap1} {fam.pf_ap2}</td>
                <td>{fam.parentesco}</td>
                <td> Sí </td>
            </tr>
        )
    })


    const displayFamNoEsPac = famNoEsPaciente.map((fam) => {
        return (
            <tr id="displayPacRel">
                <td>{fam.f_nom} {fam.f_ap1} {fam.f_ap2}</td>
                <td>{fam.parentesco}</td>
                <td> No </td>
            </tr>
        )
    })


    /*--------- Cargar Imagen ----------------*/

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/png') {
                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        const base64String = reader.result;
                        setImage(base64String);
                    };
                }
            }
            else {
                event.target.value = '';
                event.target.setCustomValidity('Solo se permiten archivos con extensión: .jpg, .jpeg, .png');
                event.target.reportValidity();      // es como un Alert
            }
        }
    }


    /*********** Filtros *****************/

    // Buscar pac activos, inactivos
    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value);
            setPacientes(data);
        }
    }

    // Pac activos, inactivos
    const limpiarFiltro = () => {
        setSelectedValue("");

        //limpiarSelectInicial();
    }

    // Limpiar select inicial: Seleccionar paciente que tiene relación con el familiar
    const limpiarSelectInicial = () => {
        setSelectValueInicial("");
        setIdSelectPac("");
        setFamEsPaciente([]);
        setFamNoEsPaciente([]);
    }


    /********* Form y Busqueda de Familiar a ingresar ******/
    const buscarFam = async (event) => {
        setInputBuscarFam(event.target.value);
        try {
            const data = await FamiliaresService.getFamiliarBuscador(event.target.value);

            setNombre(data[0].nombre)
            setAp1(data[0].ap1);
            setAp2(data[0].ap2);
            setEdad(data[0].edad);
            setSexo(data[0].sexo);
            setEdoCivil(data[0].edo_civil);
            setTel(data[0].tel);
            setCorreo(data[0].correo);
            setDomicilio(data[0].domicilio);
            setCiudad(data[0].ciudad);
            setCodPostal(data[0].cod_pos);
            setEstado(data[0].edo);
            setPais(data[0].pais);

        } catch (err) {
            //alert("No existen pacientes con la letra ingresada",err);
            mostrarError("No existen pacientes con la letra ingresada");
        }
    }

    const buscarFiltroFam = async (event) => {
        let optNum = parseInt(event.target.value)
        if (event.target.value !== "") {
            const data = await FamiliaresService.getFamiliarIndividual(event.target.value);
            setSelectedValueFam(optNum);

            let sexo = InformacionPacientes.sexoPaciente(data[0].sexo);
            let edocivil = InformacionPacientes.estadoCivil(data[0].edo_civil);
            let estado = InformacionPacientes.estadosRepublica(data[0].edo);
            let pais = InformacionPacientes.paisesPaciente(data[0].pais);

            setNombre(data[0].nombre)
            setAp1(data[0].ap1);
            setAp2(data[0].ap2);
            setEdad(data[0].edad);
            setSexo(sexo);
            setEdoCivil(edocivil);
            setTel(data[0].tel);
            setCorreo(data[0].correo);
            setDomicilio(data[0].domicilio);
            setCiudad(data[0].ciudad);
            setCodPostal(data[0].cod_pos);
            setEstado(estado);
            setPais(pais);

        }
    }

    const limpiarFiltroFam = () => {
        setSelectedValueFam("");
        setInputBuscarFam("");

        setNombre("");
        setAp1("");
        setAp2("");
        setParentescoFam("");
        setEdad("");
        setSexo("");
        setEdoCivil("");
        setTel("");
        setCorreo("");
        setDomicilio("");
        setCiudad("");
        setCodPostal("");
        setEstado("");
        setPais("");
    }


    const limpiarFiltroPac = () => {
        setSelectedValuePac("");
        // document.getElementById("input-nombre-pf").value='';

        setParentescoPac("");

        setNomUser_p("");
        setNombre_p("");
        setAp1_p("");
        setAp2_p("");
        setSexo_p("");
        setEdad_p("");
        setEscolaridad_p("");
        setProfesion_p("");
        setTrabajoActual_p("");
        setPrefSexual_p("");
        setEdoCivil_p("");
        setTel_p("");
        setCorreo_p("");
        setDomicilio_p("");
        setCodpostal_p("");
        setCiudad_p("");
        setEstado_p("");
        setPais_p("");
        setFotografia_p("");
        setFoto("0");
    }


    /******************* *************/


    /* Datos familiar */

    const handleNombre = (event) => {
        setNombre(event.target.value);
    }
    const handleAp1 = (event) => {
        setAp1(event.target.value);
    }
    const handleAp2 = (event) => {
        setAp2(event.target.value);
    }
    const handleParentescoFam = (event) => {
        setParentescoFam(event.target.value);
    }
    const handleParentescoPac = (event) => {
        setParentescoPac(event.target.value);
    }
    const handleSexo = (event) => {
        setSexo(event.target.value);
    }
    const handleEdoCivil = (event) => {
        setEdoCivil(event.target.value);
    }
    const handleEdad = (event) => {
        setEdad(parseInt(event.target.value));
    }
    const handleDomicilio = (event) => {
        setDomicilio(event.target.value);
    }
    const handleCiudad = (event) => {
        setCiudad(event.target.value);
    }
    const handleCodPostal = (event) => {
        setCodPostal(parseInt(event.target.value));
    }
    const handleEstado = (event) => {
        setEstado(event.target.value);
    }
    const handlePais = (event) => {
        setPais(event.target.value);
    }
    const handleTel = (event) => {
        setTel(event.target.value);
    }
    const handleCorreo = (event) => {
        setCorreo(event.target.value);
    }

    const handleEsPac = (event) => {
        setEsPac(event.target.value);
    }

    const handleFamiliarBuscar = (event) => {
        setFamiliarBuscar(event.target.value);
    }

    const handleSalir = (event) => {
        // navigate('/familiares');    // local
        navigate('/familiares');    // web
    }


    const handleSubmit = async () => {

        if (esPac === 'no') {   // el familiar no es paciente

            if (image !== '') {     // si se carga una foto de perfil

                let formato = "";
                if (image.includes("data:image/jpeg;base64,")) {
                    formato = "jpeg"
                } else if (image.includes("data:image/jpg;base64,")) {
                    formato = "jpg"
                } else if (image.includes("data:image/png;base64,")) {
                    formato = "png";
                }

                // ¿Quieres realizar una búscqueda?

                if (famBuscar === "no") {

                    // guardar el último id_familiar en: penultimo
                    const penultimo = await FamiliaresService.getUltimoFamiliar();

                    // crear familiar
                    let familiar = {
                        nombre: nombre,
                        ap1: ap1,
                        ap2: ap2,
                        edad: edad,
                        sexo: sexo,
                        edo_civil: edoCivil,
                        domicilio: domicilio,
                        ciudad: ciudad,
                        cod_pos: codPostal,
                        edo: estado,
                        pais: pais,
                        tel: tel,
                        correo: correo,
                        fotografia: image,  // cargar imagen
                        formatoImagen: formato      // cargar formato
                    }

                    // crear objeto parentesco de Familiar
                    let parent = {
                        parentesco: parentescoFam
                    }

                    // idselectpac: id del paciente


                    if (idselectpac !== "" &&               // si se han rellenado los campos*
                        parent.parentesco !== "" &&
                        familiar.nombre !== "" &&
                        familiar.ap1 !== "" &&
                        familiar.ap2 !== "") {

                        // validar que no se repita parentesco

                        if (famNoEsPaciente.length !== 0) {      // si hay familiares no pac, relacionados con el pac

                            let cont = 0;

                            // famNoEsPaciente, obtiene lista de los fam relacionados con el paciente
                            for (let i = 0; i < famNoEsPaciente.length; i++) {

                                if (famNoEsPaciente[i].parentesco === "padre" ||     // parent que no se pueden repetir
                                    famNoEsPaciente[i].parentesco === "madre" ||
                                    famNoEsPaciente[i].parentesco === "esposo" ||
                                    famNoEsPaciente[i].parentesco === "esposa" ||
                                    famNoEsPaciente[i].parentesco === "padrastro" ||
                                    famNoEsPaciente[i].parentesco === "madrastra" ||
                                    famNoEsPaciente[i].parentesco === "suegro") {

                                    if (famNoEsPaciente[i].parentesco === parent.parentesco) {

                                        cont++;

                                    }
                                }
                            }

                            if (cont > 0) {

                                mostrarError(`Ya existe un registro con el parentesco: ${parent.parentesco}`);

                            } else {

                                let cont = 0;

                                let nomParent = familiar.nombre + familiar.ap1 + familiar.ap2;

                                // validar para Advertencia, se repite: nombre ap1 ap2
                                for (let i = 0; i < famNoEsPaciente.length; i++) {

                                    let nomF = famNoEsPaciente[i].f_nom + famNoEsPaciente[i].f_ap1 + famNoEsPaciente[i].f_ap2;

                                    if (nomParent === nomF) {

                                        cont++;

                                    }
                                }

                                if (cont > 0) {

                                    Swal.fire({
                                        title: '¡Advertencia!',
                                        text: `Ya existe un registro con el nombre: ${nomParent} \n ¿Deseas continuar?`,
                                        icon: 'warning',
                                        confirmButtonText: 'Aceptar',
                                        showCancelButton: true
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {

                                            await FamiliaresService.createFamiliares(familiar);     // crear registro nuevo Familiar

                                            const ultFam = await FamiliaresService.getUltimoFamiliar();    // obtener último id_familar

                                            if (penultimo.id_familiar < ultFam.id_familiar) {

                                                // completar objeto: parent  -- parentesco
                                                parent.fk_familiar = ultFam.id_familiar;
                                                parent.fk_paciente = idselectpac;
                                                parent.fk_paciente_familiar = null;

                                                await ParentescoService.createParentesco(parent);   // crear parentesco

                                                Toast.fire("¡El familiar se registró correctamente!", "", "success")
                                                    .then((result) => {
                                                        if (result.isConfirmed) {
                                                            // navigate('/familiares');    // local
                                                            navigate('/familiares');    // web
                                                        }
                                                    }).then(function () {
                                                        // navigate('/familiares');    // local
                                                        navigate('/familiares');    // web
                                                    });
                                            }
                                        } else if (result.isDenied) {

                                            Swal.fire("No se creó el registro.", "", "info");

                                        }
                                    });

                                } else {

                                    await FamiliaresService.createFamiliares(familiar);     // crear registro nuevo Familiar

                                    const ultFam = await FamiliaresService.getUltimoFamiliar();    // obtener último id_familar

                                    if (penultimo.id_familiar < ultFam.id_familiar) {

                                        // completar objeto: parent  -- parentesco
                                        parent.fk_familiar = ultFam.id_familiar;
                                        parent.fk_paciente = idselectpac;
                                        parent.fk_paciente_familiar = null;

                                        await ParentescoService.createParentesco(parent);   // crear parentesco

                                        Toast.fire("¡El familiar se registró correctamente!", "", "success")
                                            .then((result) => {
                                                if (result.isConfirmed) {
                                                    // navigate('/familiares');    // local
                                                    navigate('/familiares');    // web
                                                }
                                            }).then(function () {
                                                // navigate('/familiares');    // local
                                                navigate('/familiares');    // web
                                            });
                                    }
                                }
                            }

                        } else {    // si NO hay familiares no_pac, relacionados con el pac

                            let cont = 0;

                            let nomParent = familiar.nombre + familiar.ap1 + familiar.ap2;

                            // validar para Advertencia, se repite: nombre ap1 ap2
                            for (let i = 0; i < getFamiliar.length; i++) {

                                let nomF = getFamiliar[i].nombre + getFamiliar[i].ap1 + getFamiliar[i].ap2;

                                if (nomF === nomParent) {

                                    cont++;

                                }
                            }

                            if (cont > 0) {

                                Swal.fire({
                                    title: '¡Advertencia!',
                                    text: `Ya existe un registro con el nombre: ${nomParent} \n ¿Deseas continuar?`,
                                    icon: 'warning',
                                    confirmButtonText: 'Aceptar',
                                    showCancelButton: true
                                }).then(async (result) => {
                                    if (result.isConfirmed) {

                                        await FamiliaresService.createFamiliares(familiar);     // crear registro nuevo Familiar

                                        const ultFam = await FamiliaresService.getUltimoFamiliar();    // obtener último id_familar

                                        if (penultimo.id_familiar < ultFam.id_familiar) {

                                            // completar objeto: parent  -- parentesco
                                            parent.fk_familiar = ultFam.id_familiar;
                                            parent.fk_paciente = idselectpac;
                                            parent.fk_paciente_familiar = null;

                                            await ParentescoService.createParentesco(parent);   // crear parentesco

                                            Toast.fire("¡El familiar se registró correctamente!", "", "success")
                                                .then((result) => {
                                                    if (result.isConfirmed) {
                                                        // navigate('/familiares');    // local
                                                        navigate('/familiares');    // web
                                                    }
                                                }).then(function () {
                                                    // navigate('/familiares');    // local
                                                    navigate('/familiares');    // web
                                                });
                                        }
                                    } else if (result.isDenied) {

                                        Swal.fire("No se creó el registro.", "", "info");

                                    }
                                });

                            } else {    // si no existe coincidencia

                                await FamiliaresService.createFamiliares(familiar);     // crear registro nuevo Familiar

                                const ultFam = await FamiliaresService.getUltimoFamiliar();    // obtener último id_familar

                                if (penultimo.id_familiar < ultFam.id_familiar) {

                                    // completar objeto: parent  -- parentesco
                                    parent.fk_familiar = ultFam.id_familiar;
                                    parent.fk_paciente = idselectpac;
                                    parent.fk_paciente_familiar = null;

                                    await ParentescoService.createParentesco(parent);   // crear parentesco

                                    Toast.fire("¡El familiar se registró correctamente!", "", "success")
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                // navigate('/familiares');    // local
                                                navigate('/familiares');    // web
                                            }
                                        }).then(function () {
                                            // navigate('/familiares');    // local
                                            navigate('/familiares');    // web
                                        });
                                }
                            }
                        }
                    }
                } else if (famBuscar === "si") {

                    // ya no se crea familiar nuevo
                    // solo se va a crear un nuevo parentesco

                    // crear objeto parentesco de Familiar
                    let parent = {
                        parentesco: parentescoFam,
                        fk_familiar: selectedValueFam,
                        fk_paciente: idselectpac,
                        fk_paciente_familiar: null
                    }

                    // Validar objeto completo
                    if (parent.parentesco !== "" &&
                        parent.fk_familiar.selectedValueFam !== "" &&
                        parent.fk_paciente.idselectpac !== "" &&
                        parent.fk_paciente_familiar === null) {

                        await ParentescoService.createParentesco(parent);   // crear parentesco

                        Toast.fire("¡El familiar se registró correctamente!", "", "success")
                            .then((result) => {
                                if (result.isConfirmed) {
                                    // navigate('/familiares');    // local
                                    navigate('/familiares');    // web
                                }
                            }).then(function () {
                                // navigate('/familiares');    // local
                                navigate('/familiares');    // web
                            });

                    } else {

                        mostrarError("Es necesario seleccionar una parentesco.");

                    }
                }

            } else {    // ***** Si NO es paciente y NO se carga foto de perfil... *****

                // ¿Quieres realizar una búscqueda?

                if (famBuscar === "no") {

                    // guardar el último id_familiar en: penultimo
                    const penultimo = await FamiliaresService.getUltimoFamiliar();

                    // crear objeto familiar
                    let familiar = {
                        nombre: nombre,
                        ap1: ap1,
                        ap2: ap2,
                        edad: edad,
                        sexo: sexo,
                        edo_civil: edoCivil,
                        domicilio: domicilio,
                        ciudad: ciudad,
                        cod_pos: codPostal,
                        edo: estado,
                        pais: pais,
                        tel: tel,
                        correo: correo,
                        fotografia: '',     // campo vacío, no se seleccionó foto
                        formatoImagen: ''
                    }

                    // crear objeto parentesco de Familiar
                    let parent = {
                        parentesco: parentescoFam
                    }

                    // idselectpac: id del paciente

                    if (idselectpac !== "" &&               // si se han rellenado los campos*
                        parent.parentesco !== "" &&
                        familiar.nombre !== "" &&
                        familiar.ap1 !== "" &&
                        familiar.ap2 !== "") {

                        // validar que no se repita parentesco

                        if (famNoEsPaciente.length !== 0) {      // si hay familiares no pac, relacionados con el pac

                            let cont = 0;

                            // famNoEsPaciente, obtiene lista de los fam relacionados con el paciente
                            for (let i = 0; i < famNoEsPaciente.length; i++) {

                                if (famNoEsPaciente[i].parentesco === "padre" ||     // parent que no se pueden repetir
                                    famNoEsPaciente[i].parentesco === "madre" ||
                                    famNoEsPaciente[i].parentesco === "esposo" ||
                                    famNoEsPaciente[i].parentesco === "esposa" ||
                                    famNoEsPaciente[i].parentesco === "padrastro" ||
                                    famNoEsPaciente[i].parentesco === "madrastra" ||
                                    famNoEsPaciente[i].parentesco === "suegro") {

                                    if (famNoEsPaciente[i].parentesco === parent.parentesco) {

                                        cont++;

                                    }
                                }
                            }

                            if (cont > 0) {

                                mostrarError(`Ya existe un registro con el parentesco: ${parent.parentesco}`);

                            } else {

                                let cont = 0;

                                let nomParent = familiar.nombre + familiar.ap1 + familiar.ap2;

                                // validar para Advertencia, se repite: nombre ap1 ap2
                                for (let i = 0; i < famNoEsPaciente.length; i++) {

                                    let nomF = famNoEsPaciente[i].f_nom + famNoEsPaciente[i].f_ap1 + famNoEsPaciente[i].f_ap2;

                                    if (nomParent === nomF) {

                                        cont++;

                                    }
                                }

                                if (cont > 0) {

                                    Swal.fire({
                                        title: '¡Advertencia!',
                                        text: `Ya existe un registro con el nombre: ${nomParent} \n ¿Deseas continuar?`,
                                        icon: 'warning',
                                        confirmButtonText: 'Aceptar',
                                        showCancelButton: true
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {

                                            await FamiliaresService.createFamiliares(familiar);     // crear registro nuevo Familiar

                                            const ultFam = await FamiliaresService.getUltimoFamiliar();    // obtener último id_familar

                                            if (penultimo.id_familiar < ultFam.id_familiar) {

                                                // completar objeto: parent  -- parentesco
                                                parent.fk_familiar = ultFam.id_familiar;
                                                parent.fk_paciente = idselectpac;
                                                parent.fk_paciente_familiar = null;

                                                await ParentescoService.createParentesco(parent);   // crear parentesco

                                                Toast.fire("¡El familiar se registró correctamente!", "", "success")
                                                    .then((result) => {
                                                        if (result.isConfirmed) {
                                                            // navigate('/familiares');    // local
                                                            navigate('/familiares');    // web
                                                        }
                                                    }).then(function () {
                                                        // navigate('/familiares');    // local
                                                        navigate('/familiares');    // web
                                                    });
                                            }
                                        } else if (result.isDenied) {

                                            Swal.fire("No se creó el registro.", "", "info");

                                        }
                                    });

                                } else {

                                    await FamiliaresService.createFamiliares(familiar);     // crear registro nuevo Familiar

                                    const ultFam = await FamiliaresService.getUltimoFamiliar();    // obtener último id_familar

                                    if (penultimo.id_familiar < ultFam.id_familiar) {

                                        // completar objeto: parent  -- parentesco
                                        parent.fk_familiar = ultFam.id_familiar;
                                        parent.fk_paciente = idselectpac;
                                        parent.fk_paciente_familiar = null;

                                        await ParentescoService.createParentesco(parent);   // crear parentesco

                                        Toast.fire("¡El familiar se registró correctamente!", "", "success")
                                            .then((result) => {
                                                if (result.isConfirmed) {
                                                    // navigate('/familiares');    // local
                                                    navigate('/familiares');    // web
                                                }
                                            }).then(function () {
                                                // navigate('/familiares');    // local
                                                navigate('/familiares');    // web
                                            });
                                    }
                                }
                            }

                        } else {    // si NO hay familiares no_pac, relacionados con el pac

                            let cont = 0;

                            let nomParent = familiar.nombre + familiar.ap1 + familiar.ap2;

                            // validar para Advertencia, se repite: nombre ap1 ap2
                            for (let i = 0; i < getFamiliar.length; i++) {

                                let nomF = getFamiliar[i].nombre + getFamiliar[i].ap1 + getFamiliar[i].ap2;

                                if (nomF === nomParent) {

                                    cont++;

                                }
                            }

                            if (cont > 0) {

                                Swal.fire({
                                    title: '¡Advertencia!',
                                    text: `Ya existe un registro con el nombre: ${nomParent} \n ¿Deseas continuar?`,
                                    icon: 'warning',
                                    confirmButtonText: 'Aceptar',
                                    showCancelButton: true
                                }).then(async (result) => {
                                    if (result.isConfirmed) {

                                        await FamiliaresService.createFamiliares(familiar);     // crear registro nuevo Familiar

                                        const ultFam = await FamiliaresService.getUltimoFamiliar();    // obtener último id_familar

                                        if (penultimo.id_familiar < ultFam.id_familiar) {

                                            // completar objeto: parent  -- parentesco
                                            parent.fk_familiar = ultFam.id_familiar;
                                            parent.fk_paciente = idselectpac;
                                            parent.fk_paciente_familiar = null;

                                            await ParentescoService.createParentesco(parent);   // crear parentesco

                                            Toast.fire("¡El familiar se registró correctamente!", "", "success")
                                                .then((result) => {
                                                    if (result.isConfirmed) {
                                                        // navigate('/familiares');    // local
                                                        navigate('/familiares');    // web
                                                    }
                                                }).then(function () {
                                                    // navigate('/familiares');    // local
                                                    navigate('/familiares');    // web
                                                });
                                        }
                                    } else if (result.isDenied) {

                                        Swal.fire("No se creó el registro.", "", "info");

                                    }
                                });

                            } else {    // si no existe coincidencia

                                await FamiliaresService.createFamiliares(familiar);     // crear registro nuevo Familiar

                                const ultFam = await FamiliaresService.getUltimoFamiliar();    // obtener último id_familar

                                if (penultimo.id_familiar < ultFam.id_familiar) {

                                    // completar objeto: parent  -- parentesco
                                    parent.fk_familiar = ultFam.id_familiar;
                                    parent.fk_paciente = idselectpac;
                                    parent.fk_paciente_familiar = null;

                                    await ParentescoService.createParentesco(parent);   // crear parentesco

                                    Toast.fire("¡El familiar se registró correctamente!", "", "success")
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                // navigate('/familiares');    // local
                                                navigate('/familiares');    // web
                                            }
                                        }).then(function () {
                                            // navigate('/familiares');    // local
                                            navigate('/familiares');    // web
                                        });
                                }
                            }
                        }
                    }

                } else if (famBuscar === "si") {

                    // ya no se crea familiar nuevo
                    // solo se va a crear un nuevo parentesco

                    // crear objeto parentesco de Familiar
                    let parent = {
                        parentesco: parentescoFam,
                        fk_familiar: selectedValueFam,
                        fk_paciente: idselectpac,
                        fk_paciente_familiar: null
                    }

                    // Validar objeto completo
                    if (parent.parentesco !== "" &&
                        parent.fk_familiar.selectedValueFam !== "" &&
                        parent.fk_paciente.idselectpac !== "" &&
                        parent.fk_paciente_familiar === null) {

                        await ParentescoService.createParentesco(parent);   // crear parentesco

                        Toast.fire("¡El familiar se registró correctamente!", "", "success")
                            .then((result) => {
                                if (result.isConfirmed) {
                                    // navigate('/familiares');    // local
                                    navigate('/familiares');    // web
                                }
                            }).then(function () {
                                // navigate('/familiares');    // local
                                navigate('/familiares');    // web
                            });

                    } else {

                        mostrarError("Es necesario seleccionar una parentesco.");

                    }
                }
            }

        } else if (esPac === 'si') {   // si el Fam es Pac

            if (idselectpac !== "") {

                // crear parentesco de Familiar Paciente
                let parent = {
                    parentesco: parentescoPac,
                    fk_familiar: null,
                    fk_paciente: idselectpac,
                    fk_paciente_familiar: selectedValuePac
                }

                if (famEsPaciente.length !== 0) {

                    // validar no repetir parentesco
                    for (let i = 0; i < famEsPaciente.length; i++) {

                        if (famEsPaciente[i].parentesco === parent.parentesco) {

                            mostrarError(`Ya existe un registro con el parentesco: ${parent.parentesco}`);

                        } else {

                            await ParentescoService.createParentesco(parent);

                            Toast.fire("¡El familiar paciente se registró correctamente!", "", "success")
                                .then((result) => {
                                    if (result.isConfirmed) {
                                        // navigate('/familiares');    // local
                                        navigate('/familiares');    // web
                                    }
                                }).then(function () {
                                    // navigate('/familiares');    // local
                                    navigate('/familiares');    // web
                                });
                        }
                    }

                } else {

                    await ParentescoService.createParentesco(parent);

                    Toast.fire("¡El familiar paciente se registró correctamente!", "", "success")
                        .then((result) => {
                            if (result.isConfirmed) {
                                // navigate('/familiares');    // local
                                navigate('/familiares');    // web
                            }
                        }).then(function () {
                            // navigate('/familiares');    // local
                            navigate('/familiares');    // web
                        });
                }

            } else {
                mostrarError("Se debe seleccionar al paciente relacionado.");
            }
        }
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    return (
        <Layout>
            <Content>
                <div className="div-1-addFam">
                    <div className="div_2_addFam">
                        <div className="px_sesion_addFam">
                            <label id="lbl-enSesion-addFam">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_addFam">
                            <label>Registrar familiares del paciente</label>
                        </div>
                        <hr />
                    </div>
                    <div className="div-datos-addFam">
                        <div className="div-selectPacRel-fam">
                            <div className="selectPacRel-fam">
                                <fieldset>
                                    <legend>Seleccionar paciente que tiene relación con el familiar:</legend>
                                </fieldset>
                            </div>
                            <div className="selectPacFam-filtro">
                                <div className="selectPacFam">
                                    <select id="select-paciente-addFam" defaultValue="" onChange={cargarInfoPacRelacionado} required>
                                        <option className="option_addFam" value="" disabled >Selecciona un paciente</option>
                                        {pacientes.map((paciente) => (
                                            <option className="selected-option-addFam" value={paciente.id_paciente}>
                                                {paciente.nombre} {paciente.ap1} {paciente.ap2}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="selectFiltro">
                                    <select id="select-paciente-addFam" value={selectedValue} onChange={buscarFiltro}>
                                        <option value="" disabled>Selecciona un filtro</option>
                                        <option value="A">Activos</option>
                                        <option value="I">Inactivos</option>
                                    </select>
                                    <button id="btn-limpiar-filtro" onClick={limpiarFiltro}>
                                        <img src={img_clear} alt="img_clear" />
                                        Limpiar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="displayPacRelacionados">
                            {famEsPaciente.length !== 0 || famNoEsPaciente.length !== 0 ? (
                                <div className="displayPacRelacionados_a">
                                    <hr />
                                    <label> El paciente está relacionado con: </label>
                                    <table className="tablePacRelacionados_a">
                                        <thead>
                                            <tr id="encabezadoPacRel">
                                                <th>Nombre</th>
                                                <th>Parentesco</th>
                                                <th>Paciente</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayFamEsPac}
                                            {displayFamNoEsPac}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                        <hr />
                        <div className="radio_btn_esPac">
                            <div id="radio_label_esPac">
                                <label>¿El familiar que se desea ingresar es paciente?</label>
                            </div>
                            <div id="radio_input_esPac">
                                <div id="input_esPac">
                                    <input type="radio" id="famespacNo" name="famespac" value="no" onChange={handleEsPac} />
                                    <label htmlFor="famespacNo">No</label>
                                </div>
                                <div id="input_esPac">
                                    <input type="radio" id="famespacSi" name="famespac" value="si" onChange={handleEsPac} />
                                    <label htmlFor="famespacSi">Sí</label>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div>
                            {
                                esPac === "no" ? (
                                    <div className="div-noEsPac-addFam">
                                        <div>
                                            <p>
                                                En caso de que el familiar esté relacionado con otros pacientes,
                                                es posible que ya esté registrado.
                                            </p>
                                            <p>
                                                ¿Quieres realizar una búscqueda?
                                            </p>
                                        </div>
                                        <div id="radio_input_esPac">
                                            <div id="input_esPac">
                                                <input type="radio" id="familiarBuscarNo" name="familiarBuscar" value="no" onChange={handleFamiliarBuscar} />
                                                <label htmlFor="familiarBuscarNo">No</label>
                                            </div>
                                            <div id="input_esPac">
                                                <input type="radio" id="familiarBuscarSi" name="familiarBuscar" value="si" onChange={handleFamiliarBuscar} />
                                                <label htmlFor="familiarBuscarSi">Sí</label>
                                            </div>
                                        </div>
                                        {
                                            famBuscar === "si" ? (
                                                <div>
                                                    <div className="div-noEsPac2-addFam">
                                                        <div className="div-noEsPac_a-addFam">
                                                            <select id="select-paciente-addFam" value={selectedValueFam} onChange={buscarFiltroFam} required>
                                                                <option className="option_addFam" value="" disabled>Selecciona un familiar</option>
                                                                {getFamiliar.map((fam) => (
                                                                    <option className="selected-option-addFam" value={fam.id_familiar}>
                                                                        {fam.nombre} {fam.ap1} {fam.ap2}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="div-noEsPac_b-addFam">
                                                            <button id="btn-limpiar-filtro" onClick={limpiarFiltroFam}>
                                                                <img src={img_clear} alt="img_clear" />
                                                                Limpiar
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            Puedes realizar una búsqueda rápida para confirmar.
                                                        </p>
                                                        <div className="div_buscadorFam_addfam">
                                                            <div className="div_inputFam_fam">
                                                                <div className="input_buscar_fam">
                                                                    <input type="search" placeholder="Buscar familiar..." id="Buscador" value={inputBuscarFam} onChange={buscarFam} />
                                                                    <img src={img_search} alt="buscar" id="img_lupa"></img>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <hr></hr>

                                                    <fieldset>
                                                        <div>
                                                            <label>Selecciona el parentesco:</label>
                                                            <select id="select-parentescoDelFam" defaultValue="elige" onChange={handleParentescoFam} required>
                                                                <option value="elige" disabled>Elige una opción</option>
                                                                <option value="padre">Padre</option>
                                                                <option value="madre">Madre</option>
                                                                <option value="hijo">Hijo</option>
                                                                <option value="hermano">Hermano</option>
                                                                <option value="tio">Tío</option>
                                                                <option value="abuelo">Abuelo</option>
                                                                <option value="primo">Primo</option>
                                                                <option value="esposo">Esposo</option>
                                                                <option value="esposa">Esposa</option>
                                                                <option value="padrastro">Padrastro</option>
                                                                <option value="madrastra">Madrastra</option>
                                                                <option value="suegro">Suegro</option>
                                                                <option value="cunado">Cuñado</option>
                                                                <option value="consuegro">Consuegro</option>
                                                                <option value="concuno">Concuño</option>
                                                                <hr></hr>
                                                                <option value="amigo">Amigo</option>
                                                                <option value="vecino">Vecino</option>
                                                                <option value="conocido">Persona conocida</option>
                                                            </select>
                                                        </div>
                                                        <div className="div_content_foto_addFam">
                                                            <div className="div-foto-addFam">
                                                                {image && <img alt="vista previa" className="vista-previa" src={image} />}
                                                            </div>
                                                            <div className="div_btn_foto_addFam">
                                                                <input type="file" onChange={onImageChange} className="filetype" accept=".jpg, .jpeg, .png" />
                                                            </div>
                                                        </div>
                                                        <table id="table-addFam">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-familiar">Familiar:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-np">
                                                                        <label>{nombre}</label>
                                                                    </td>
                                                                    <td className="addFam-td-der2-np">
                                                                        <label>{ap1}</label>
                                                                    </td>
                                                                    <td className="addFam-td-der3-np">
                                                                        <label>{ap2}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-edad" htmlFor="input-edad-addPx">Edad:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-np">
                                                                        <label>{edad}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-sexo">Sexo:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-np">
                                                                        <label>{sexo}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-edo-civil" htmlFor="select-edo-civil-addPx">Estado civil:</label>
                                                                    </td>
                                                                    <td className="addFam-td-select">
                                                                        <label>{edoCivil}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-tel" htmlFor="input-tel">Teléfono:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-np">
                                                                        <label>{tel}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-correo" htmlFor="input-correo">Correo:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-a-np" colSpan={2}>
                                                                        <label>{correo}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-domicilio" htmlFor="input-domicilio">Domicilio:</label></td>
                                                                    <td className="addFam-td-der1-a-np" colSpan={2}>
                                                                        <label>{domicilio}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-ciudad" htmlFor="input-ciudad">Ciudad:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-a-np">
                                                                        <label>{ciudad}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-cp" htmlFor="input-cp">Código Postal:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-np">
                                                                        <label>{codPostal}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-edo" htmlFor="select-edo-addPx">Estado:</label>
                                                                    </td>
                                                                    <td className="addFam-td-select">
                                                                        <label>{estado}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-pais" htmlFor="select-pais-addPx">País:</label>
                                                                    </td>
                                                                    <td className="addFam-td-select">
                                                                        <label>{pais}</label>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </fieldset>
                                                </div>
                                            ) : famBuscar === "no" ? (
                                                <div>
                                                    <hr />
                                                    <fieldset>
                                                        <legend>Ingresa los datos del familiar</legend>
                                                        <div className="div-ingresarDatosFam">
                                                            <label>Selecciona el parentesco:</label>
                                                            <select id="select-parentescoDelFam" defaultValue="elige" onChange={handleParentescoFam} required>
                                                                <option value="elige" disabled>Elige una opción</option>
                                                                <option value="padre">Padre</option>
                                                                <option value="madre">Madre</option>
                                                                <option value="hijo">Hijo</option>
                                                                <option value="hermano">Hermano</option>
                                                                <option value="tio">Tío</option>
                                                                <option value="abuelo">Abuelo</option>
                                                                <option value="primo">Primo</option>
                                                                <option value="esposo">Esposo</option>
                                                                <option value="esposa">Esposa</option>
                                                                <option value="padrastro">Padrastro</option>
                                                                <option value="madrastra">Madrastra</option>
                                                                <option value="suegro">Suegro</option>
                                                                <option value="cunado">Cuñado</option>
                                                                <option value="consuegro">Consuegro</option>
                                                                <option value="concuno">Concuño</option>
                                                                <hr></hr>
                                                                <option value="amigo">Amigo</option>
                                                                <option value="vecino">Vecino</option>
                                                                <option value="conocido">Persona conocida</option>
                                                            </select>
                                                        </div>
                                                        <div className="div_content_foto_addFam">
                                                            <div className="div-foto-addFam">
                                                                {image && <img alt="vista previa" className="vista-previa" src={image} />}
                                                            </div>
                                                            <div className="div_btn_foto_addFam">
                                                                <input type="file" onChange={onImageChange} className="filetype" accept=".jpg, .jpeg, .png" />
                                                            </div>
                                                        </div>
                                                        <table id="table-addFam">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-familiar">Familiar:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-np">
                                                                        <input type="text" id="input-nombre-addPx" placeholder="Nombre" onChange={handleNombre} required></input>
                                                                    </td>
                                                                    <td className="addFam-td-der2-np">
                                                                        <input type="text" id="input-ap1-addPx" placeholder="Apellido paterno" onChange={handleAp1} required></input>
                                                                    </td>
                                                                    <td className="addFam-td-der3-np">
                                                                        <input type="text" id="input-ap2-addPx" placeholder="Apellido materno" onChange={handleAp2} required></input>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-edad" htmlFor="input-edad-addPx">Edad:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-np">
                                                                        <input type="number" id="input-edad-addPx" onChange={handleEdad}></input>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-sexo">Sexo:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-np" colSpan={2}>
                                                                        <div className="radio-container">
                                                                            <input type="radio" id="masculino" name="sexo" value="m" onChange={handleSexo} />
                                                                            <label htmlFor="masculino">Masculino</label>
                                                                            <input type="radio" id="femenino" name="sexo" value="f" onChange={handleSexo} />
                                                                            <label htmlFor="femenino">Femenino</label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-edo-civil" htmlFor="select-edo-civil-addPx">Estado civil:</label>
                                                                    </td>
                                                                    <td className="addFam-td-select">
                                                                        <select id="select-edo-civil-addPx" onChange={handleEdoCivil}>
                                                                            <option value="elige">Elige una opción</option>
                                                                            <option value="ca">Casado</option>
                                                                            <option value="div">Divorciado</option>
                                                                            <option value="sol">Soltero</option>
                                                                            <option value="sep">Separado</option>
                                                                            <option value="viu">Viudo</option>
                                                                            <option value="con">Concubinato</option>
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-tel" htmlFor="input-tel">Teléfono:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-np">
                                                                        <input type="tel" id="input-tel" onChange={handleTel}></input>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-correo" htmlFor="input-correo">Correo:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-a-np" colSpan={2}>
                                                                        <input type="email" id="input-correo" placeholder="Correo electrónico" onChange={handleCorreo}></input>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-domicilio" htmlFor="input-domicilio">Domicilio:</label></td>
                                                                    <td className="addFam-td-der1-a-np" colSpan={2}>
                                                                        <input type="text" id="input-domicilio" placeholder="Calle, No., Colonia..." onChange={handleDomicilio}></input>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-ciudad" htmlFor="input-ciudad">Ciudad:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-a-np" colSpan={2}>
                                                                        <input type="text" id="input-ciudad" placeholder="Ciudad..." onChange={handleCiudad}></input>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-cp" htmlFor="input-cp">Código Postal:</label>
                                                                    </td>
                                                                    <td className="addFam-td-der1-np">
                                                                        <input type="number" id="input-cp" placeholder="Código postal" onChange={handleCodPostal}></input>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-edo" htmlFor="select-edo-addPx">Estado:</label>
                                                                    </td>
                                                                    <td className="addFam-td-select" colSpan={3}>
                                                                        <select id="select-edo-addPx" defaultValue="mi" onChange={handleEstado}>
                                                                            <option value="a">Aguascalientes</option>
                                                                            <option value="b">Baja California</option>
                                                                            <option value="bs">Baja California Sur</option>
                                                                            <option value="c">Campeche</option>
                                                                            <option value="ch">Chiapas</option>
                                                                            <option value="chi">Chihuahua</option>
                                                                            <option value="co">Coahuila</option>
                                                                            <option value="col">Colima</option>
                                                                            <option value="cd">Ciudad de México</option>
                                                                            <option value="d">Durango</option>
                                                                            <option value="em">Estado de México</option>
                                                                            <option value="g">Guanajuato</option>
                                                                            <option value="gu">Guerrero</option>
                                                                            <option value="h">Hidalgo</option>
                                                                            <option value="j">Jalisco</option>
                                                                            <option value="mi">Michoacán</option>
                                                                            <option value="mo">Morelos</option>
                                                                            <option value="n">Nayarit</option>
                                                                            <option value="nl">Nuevo León</option>
                                                                            <option value="o">Oaxaca</option>
                                                                            <option value="p">Puebla</option>
                                                                            <option value="q">Querétaro</option>
                                                                            <option value="qr">Quintana Roo</option>
                                                                            <option value="sn">San Luis Potosí</option>
                                                                            <option value="s">Sinaloa</option>
                                                                            <option value="so">Sonora</option>
                                                                            <option value="t">Tabasco</option>
                                                                            <option value="ta">Tamaulipas</option>
                                                                            <option value="tl">Tlaxcala</option>
                                                                            <option value="v">Veracruz</option>
                                                                            <option value="y">Yucatán</option>
                                                                            <option value="z">Zacatecas</option>
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="addFam-td-izq-np">
                                                                        <label className="label-pais" htmlFor="select-pais-addPx">País:</label>
                                                                    </td>
                                                                    <td className="addFam-td-select" colSpan={3}>
                                                                        <select id="select-pais-addPx" defaultValue="MX" onChange={handlePais}>
                                                                            <option value="AF">Afganistán</option>
                                                                            <option value="AL">Albania</option>
                                                                            <option value="DE">Alemania</option>
                                                                            <option value="AD">Andorra</option>
                                                                            <option value="AO">Angola</option>
                                                                            <option value="AI">Anguilla</option>
                                                                            <option value="AQ">Antártida</option>
                                                                            <option value="AG">Barbuda</option>
                                                                            <option value="AN">Holandesas</option>
                                                                            <option value="SA">Arabia Saudí</option>
                                                                            <option value="DZ">Argelia</option>
                                                                            <option value="AR">Argentina</option>
                                                                            <option value="AM">Armenia</option>
                                                                            <option value="AW">Aruba</option>
                                                                            <option value="AU">Australia</option>
                                                                            <option value="AT">Austria</option>
                                                                            <option value="AZ">Azerbaiyán</option>
                                                                            <option value="BS">Bahamas</option>
                                                                            <option value="BH">Bahrein</option>
                                                                            <option value="BD">Bangladesh</option>
                                                                            <option value="BB">Barbados</option>
                                                                            <option value="BE">Bélgica</option>
                                                                            <option value="BZ">Belice</option>
                                                                            <option value="BJ">Benin</option>
                                                                            <option value="BM">Bermudas</option>
                                                                            <option value="BY">Bielorrusia</option>
                                                                            <option value="MM">Birmania</option>
                                                                            <option value="BO">Bolivia</option>
                                                                            <option value="BA">Bosnia</option>
                                                                            <option value="BW">Botswana</option>
                                                                            <option value="BR">Brasil</option>
                                                                            <option value="BN">Brunei</option>
                                                                            <option value="BG">Bulgaria</option>
                                                                            <option value="BF">Burkina Faso</option>
                                                                            <option value="BI">Burundi</option>
                                                                            <option value="BT">Bután</option>
                                                                            <option value="CV">Cabo Verde</option>
                                                                            <option value="KH">Camboya</option>
                                                                            <option value="CM">Camerún</option>
                                                                            <option value="CA">Canadá</option>
                                                                            <option value="TD">Chad</option>
                                                                            <option value="CL">Chile</option>
                                                                            <option value="CN">China</option>
                                                                            <option value="CY">Chipre</option>
                                                                            <option value="VA">Vaticano</option>
                                                                            <option value="CO">Colombia</option>
                                                                            <option value="KM">Comores</option>
                                                                            <option value="CG">Congo</option>
                                                                            <option value="CD">Congo</option>
                                                                            <option value="KR">Corea</option>
                                                                            <option value="KP">Corea del Norte</option>
                                                                            <option value="CI">Costa de Marfíl</option>
                                                                            <option value="CR">Costa Rica</option>
                                                                            <option value="HR">Croacia</option>
                                                                            <option value="CU">Cuba</option>
                                                                            <option value="DK">Dinamarca</option>
                                                                            <option value="DJ">Djibouti</option>
                                                                            <option value="DM">Dominica</option>
                                                                            <option value="EC">Ecuador</option>
                                                                            <option value="EG">Egipto</option>
                                                                            <option value="SV">El Salvador</option>
                                                                            <option value="AE">Emiratos Árabes U.</option>
                                                                            <option value="ER">Eritrea</option>
                                                                            <option value="SI">Eslovenia</option>
                                                                            <option value="ES">España</option>
                                                                            <option value="US">Estados Unidos</option>
                                                                            <option value="EE">Estonia</option>
                                                                            <option value="ET">Etiopía</option>
                                                                            <option value="FJ">Fiji</option>
                                                                            <option value="PH">Filipinas</option>
                                                                            <option value="FI">Finlandia</option>
                                                                            <option value="FR">Francia</option>
                                                                            <option value="GA">Gabón</option>
                                                                            <option value="GM">Gambia</option>
                                                                            <option value="GE">Georgia</option>
                                                                            <option value="GH">Ghana</option>
                                                                            <option value="GI">Gibraltar</option>
                                                                            <option value="GD">Granada</option>
                                                                            <option value="GR">Grecia</option>
                                                                            <option value="GL">Groenlandia</option>
                                                                            <option value="GP">Guadalupe</option>
                                                                            <option value="GU">Guam</option>
                                                                            <option value="GT">Guatemala</option>
                                                                            <option value="GY">Guayana</option>
                                                                            <option value="GF">Guayana Francesa</option>
                                                                            <option value="GN">Guinea</option>
                                                                            <option value="GQ">Guinea Ecuatorial</option>
                                                                            <option value="GW">Guinea-Bissau</option>
                                                                            <option value="HT">Haití</option>
                                                                            <option value="HN">Honduras</option>
                                                                            <option value="HU">Hungría</option>
                                                                            <option value="IN">India</option>
                                                                            <option value="ID">Indonesia</option>
                                                                            <option value="IQ">Irak</option>
                                                                            <option value="IR">Irán</option>
                                                                            <option value="IE">Irlanda</option>
                                                                            <option value="BV">Isla Bouvet</option>
                                                                            <option value="CX">Isla de Christmas</option>
                                                                            <option value="IS">Islandia</option>
                                                                            <option value="KY">Islas Caimán</option>
                                                                            <option value="CK">Islas Cook</option>
                                                                            <option value="CC">Islas de Cocos</option>
                                                                            <option value="FO">Islas Faroe</option>
                                                                            <option value="HM">Islas Heard</option>
                                                                            <option value="FK">Islas Malvinas</option>
                                                                            <option value="MP">Islas Marianas</option>
                                                                            <option value="MH">Islas Marshall</option>
                                                                            <option value="PW">Islas Palau</option>
                                                                            <option value="SB">Islas Salomón</option>
                                                                            <option value="SJ">Islas Svalbard</option>
                                                                            <option value="TK">Islas Tokelau</option>
                                                                            <option value="TC">Islas Turks</option>
                                                                            <option value="IL">Israel</option>
                                                                            <option value="IT">Italia</option>
                                                                            <option value="JM">Jamaica</option>
                                                                            <option value="JP">Japón</option>
                                                                            <option value="JO">Jordania</option>
                                                                            <option value="KZ">Kazajistán</option>
                                                                            <option value="KE">Kenia</option>
                                                                            <option value="KG">Kirguizistán</option>
                                                                            <option value="KI">Kiribati</option>
                                                                            <option value="KW">Kuwait</option>
                                                                            <option value="LA">Laos</option>
                                                                            <option value="LS">Lesotho</option>
                                                                            <option value="LV">Letonia</option>
                                                                            <option value="LB">Líbano</option>
                                                                            <option value="LR">Liberia</option>
                                                                            <option value="LY">Libia</option>
                                                                            <option value="LI">Liechtenstein</option>
                                                                            <option value="LT">Lituania</option>
                                                                            <option value="LU">Luxemburgo</option>
                                                                            <option value="MK">Macedonia</option>
                                                                            <option value="MG">Madagascar</option>
                                                                            <option value="MY">Malasia</option>
                                                                            <option value="MW">Malawi</option>
                                                                            <option value="MV">Maldivas</option>
                                                                            <option value="ML">Malí</option>
                                                                            <option value="MT">Malta</option>
                                                                            <option value="MA">Marruecos</option>
                                                                            <option value="MQ">Martinica</option>
                                                                            <option value="MU">Mauricio</option>
                                                                            <option value="MR">Mauritania</option>
                                                                            <option value="YT">Mayotte</option>
                                                                            <option value="MX">México</option>
                                                                            <option value="FM">Micronesia</option>
                                                                            <option value="MD">Moldavia</option>
                                                                            <option value="MC">Mónaco</option>
                                                                            <option value="MN">Mongolia</option>
                                                                            <option value="MS">Montserrat</option>
                                                                            <option value="MZ">Mozambique</option>
                                                                            <option value="NA">Namibia</option>
                                                                            <option value="NR">Nauru</option>
                                                                            <option value="NP">Nepal</option>
                                                                            <option value="NI">Nicaragua</option>
                                                                            <option value="NE">Níger</option>
                                                                            <option value="NG">Nigeria</option>
                                                                            <option value="NU">Niue</option>
                                                                            <option value="NF">Norfolk</option>
                                                                            <option value="NO">Noruega</option>
                                                                            <option value="NC">Nueva Caledonia</option>
                                                                            <option value="NZ">Nueva Zelanda</option>
                                                                            <option value="OM">Omán</option>
                                                                            <option value="NL">Países Bajos</option>
                                                                            <option value="PA">Panamá</option>
                                                                            <option value="PG">Papúa Nueva Guinea</option>
                                                                            <option value="PK">Paquistán</option>
                                                                            <option value="PY">Paraguay</option>
                                                                            <option value="PE">Perú</option>
                                                                            <option value="PN">Pitcairn</option>
                                                                            <option value="PF">Polinesia Francesa</option>
                                                                            <option value="PL">Polonia</option>
                                                                            <option value="PT">Portugal</option>
                                                                            <option value="PR">Puerto Rico</option>
                                                                            <option value="QA">Qatar</option>
                                                                            <option value="UK">Reino Unido</option>
                                                                            <option value="CF">Rep. Centroafricana</option>
                                                                            <option value="CZ">Rep. Checa</option>
                                                                            <option value="ZA">Rep. de Sudáfrica</option>
                                                                            <option value="DO">Rep. Dominicana</option>
                                                                            <option value="SK">Rep. Eslovaca</option>
                                                                            <option value="RE">Reunión</option>
                                                                            <option value="RW">Ruanda</option>
                                                                            <option value="RO">Rumania</option>
                                                                            <option value="RU">Rusia</option>
                                                                            <option value="EH">Sahara Occidental</option>
                                                                            <option value="KN">Saint Kitts</option>
                                                                            <option value="WS">Samoa</option>
                                                                            <option value="AS">Samoa Americana</option>
                                                                            <option value="SM">San Marino</option>
                                                                            <option value="VC">San Vicente</option>
                                                                            <option value="SH">Santa Helena</option>
                                                                            <option value="LC">Santa Lucía</option>
                                                                            <option value="ST">Santo Tomé</option>
                                                                            <option value="SN">Senegal</option>
                                                                            <option value="SC">Seychelles</option>
                                                                            <option value="SL">Sierra Leona</option>
                                                                            <option value="SG">Singapur</option>
                                                                            <option value="SY">Siria</option>
                                                                            <option value="SO">Somalia</option>
                                                                            <option value="LK">Sri Lanka</option>
                                                                            <option value="PM">St Pierre</option>
                                                                            <option value="SZ">Suazilandia</option>
                                                                            <option value="SD">Sudán</option>
                                                                            <option value="SE">Suecia</option>
                                                                            <option value="CH">Suiza</option>
                                                                            <option value="SR">Surinam</option>
                                                                            <option value="TH">Tailandia</option>
                                                                            <option value="TW">Taiwán</option>
                                                                            <option value="TZ">Tanzania</option>
                                                                            <option value="TJ">Tayikistán</option>
                                                                            <option value="TP">Timor Oriental</option>
                                                                            <option value="TG">Togo</option>
                                                                            <option value="TO">Tonga</option>
                                                                            <option value="TT">Trinidad y Tobago</option>
                                                                            <option value="TN">Túnez</option>
                                                                            <option value="TM">Turkmenistán</option>
                                                                            <option value="TR">Turquía</option>
                                                                            <option value="TV">Tuvalu</option>
                                                                            <option value="UA">Ucrania</option>
                                                                            <option value="UG">Uganda</option>
                                                                            <option value="UY">Uruguay</option>
                                                                            <option value="UZ">Uzbekistán</option>
                                                                            <option value="VU">Vanuatu</option>
                                                                            <option value="VE">Venezuela</option>
                                                                            <option value="VN">Vietnam</option>
                                                                            <option value="YE">Yemen</option>
                                                                            <option value="YU">Yugoslavia</option>
                                                                            <option value="ZM">Zambia</option>
                                                                            <option value="ZW">Zimbabue</option>
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </fieldset>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )
                                        }
                                    </div>
                                ) : (
                                    esPac === "si" ? (
                                        <div className="div-siEsPac">
                                            <div className="div-siEsPac2">
                                                <div className="div-siEsPac3">
                                                    <fieldset>
                                                        <legend>Seleccionar el paciente que es familiar: </legend>
                                                    </fieldset>
                                                </div>
                                                <div className="div-siEsPac4">
                                                    <div className="div-siEsPac5">
                                                        <select id="select-paciente-addFam" value={selectedValuePac} onChange={cargarInfoPaciente} required>
                                                            <option className="option_addFam" value="" disabled >Selecciona un paciente</option>
                                                            {pacientes.map((paciente) => (
                                                                <option className="selected-option-addFam" value={paciente.id_paciente}>
                                                                    {paciente.nombre} {paciente.ap1} {paciente.ap2}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="div-siEsPac6">
                                                        <button type="button" id="btn-limpiar-filtro" onClick={limpiarFiltroPac}>
                                                            <img src={img_clear} alt="img_clear"></img>
                                                            Limpiar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="div-select-paren-sf">
                                                <label> Seleccionar el parentesco: * </label>
                                                <select id="select-parentescoDelPac" defaultValue="elige" onChange={handleParentescoPac} required>
                                                    <option value="elige" disabled>Elige una opción</option>
                                                    <option value="padre">Padre</option>
                                                    <option value="madre">Madre</option>
                                                    <option value="hijo">Hijo</option>
                                                    <option value="hermano">Hermano</option>
                                                    <option value="tio">Tío</option>
                                                    <option value="abuelo">Abuelo</option>
                                                    <option value="primo">Primo</option>
                                                    <option value="esposo">Esposo</option>
                                                    <option value="esposa">Esposa</option>
                                                    <option value="padrastro">Padrastro</option>
                                                    <option value="madrastra">Madrastra</option>
                                                    <option value="suegro">Suegro</option>
                                                    <option value="cunado">Cuñado</option>
                                                    <option value="consuegro">Consuegro</option>
                                                    <option value="concuno">Concuño</option>
                                                    <hr></hr>
                                                    <option value="amigo">Amigo</option>
                                                    <option value="vecino">Vecino</option>
                                                    <option value="conocido">Persona conocida</option>
                                                </select>
                                            </div>
                                            <hr />
                                            <div className="div-avatar-addF">
                                                <div className="avatar-addF">
                                                    {
                                                        foto === "1" ? (
                                                            fotografia_p === "" ? (
                                                                <div id="img_avatar_px">
                                                                    <label> Cargando imagen...</label>
                                                                </div>
                                                            ) : (
                                                                <div id="img_avatar_px">
                                                                    <img src={fotografia_p} alt="base64"></img>
                                                                </div>
                                                            )
                                                        ) : (<div></div>)
                                                    }
                                                </div>
                                                <div className="avatar-addF2">
                                                    {
                                                        foto === "1" ? (
                                                            <div>
                                                                <label>Paciente familiar:</label>
                                                                <label>{nombre_p} {ap1_p} {ap2_p}</label>
                                                            </div>
                                                        ) : (<div></div>)
                                                    }
                                                </div>
                                            </div>
                                            <div className="div-table-EsPac-addFam">
                                                <table id="table-EsPac-addFam">
                                                    <tbody>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Nombre de usuario:</label>
                                                            </td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label placeholder="Nombre de usuario"> {nomuser_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Nombre:</label>
                                                            </td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label placeholder="Nombre"> {nombre_p} {ap1_p} {ap2_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Edad:</label>
                                                            </td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {edad_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Sexo:</label>
                                                            </td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {sexo_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Escolaridad:</label>
                                                            </td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {escolaridad_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Profesión:</label>
                                                            </td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {profesion_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Trabajo Actual:</label>
                                                            </td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {trabajoActual_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Preferencia sexual:</label>
                                                            </td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {prefSexual_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Estado civil:</label>
                                                            </td>
                                                            <td className="addFam-td-select">
                                                                <label> {edoCivil_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Teléfono:</label>
                                                            </td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {tel_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Correo:</label>
                                                            </td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {correo_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Domicilio:</label></td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {domicilio_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Ciudad:</label>
                                                            </td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {ciudad_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Código Postal:</label>
                                                            </td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {codPostal_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>Estado:</label></td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {estado_p} </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="addFam-td-izq-ep">
                                                                <label>País:</label></td>
                                                            <td className="addFam-td-der1-ep">
                                                                <label> {pais_p} </label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                )
                            }
                        </div>
                    </div>
                    <div className="div-autoregistro-botones-addFam">
                        <div className="div-btn-alineacion-addFam">
                            <div className="div-addFam-enviar">
                                <button value="enviar" id="btn-enviar-addFam" onClick={handleSubmit}>Enviar</button>
                            </div>
                            <div className="div-addFam-cancelar">
                                <button value="cancelar" id="btn-cancelar-addFam" onClick={handleSalir}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default AddFamiliar;