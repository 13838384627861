// FormularioCita.js
import React from "react";
import "../../styles/FormularioEditarCita.css";

const FormularioRegistroCita = ({ pacientes, fechaF, horaI, horaF }) => {
  return (
    <div>
      <table className="table-registro-cita">
        <tbody>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-paciente-registro" htmlFor="select-paciente"> Paciente: </label>
            </td>
            <td>
              <select className="selected-pax" id="select-paciente">
                <option className="selected-option"> Selecciona una opción </option>
                {pacientes.map((paciente, index) => (
                  <option key={index} className="selected-option" value={paciente.id_paciente}>
                    {paciente.nombre} {paciente.ap1} {paciente.ap2}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-fecha-registro" htmlFor="input-fecha"> Fecha: </label>
            </td>
            <td className="registrar-cita-td-der">
              <input type="date" id="input-fecha" defaultValue={fechaF} />
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-horaI-registro" htmlFor="input-horaI"> Hora Inicio: </label></td>
            <td className="registrar-cita-td-der">
              <input type="time" id="input-horaI" defaultValue={horaI} /></td>
          </tr>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-horaF-registro" htmlFor="input-horaF"> Hora Final: </label>
            </td>
            <td className="registrar-cita-td-der">
              <input type="time" id="input-horaF" defaultValue={horaF} />
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-comentario-registro"> Modalidad: </label>
            </td>
            <td className="registrar-cita-td-der">
              <select className="selected-pax" id="select-modalidad" defaultValue="elegir">
                <option className="selected-option" value="elegir" disabled> Selecciona una opción </option>
                <option className="selected-option" value="presencial">Presencial</option>
                <option className="selected-option" value="videollamada">Videollamada</option>
                <option className="selected-option" value="telefono">Teléfono</option>
                <option className="selected-option" value="mensajes">Mensajes: voz, texto</option>
              </select>
            </td>
          </tr>
          <tr className="table-line-bottom">
            <td className="registrar-cita-td-izq">
              <label className="lbl-comentario-registro" htmlFor="input-comentario"> Comentario: </label>
            </td>
            <td className="registrar-cita-td-der">
              <input type="text" placeholder="Deja un comentario..." id="input-comentario" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FormularioRegistroCita;
