
                          /* REACT ROUTER APP */

//import { BrowserRouter, Routes, Route } from "react-router-dom";      /* Entorno Local */

import { HashRouter as Router, Routes, Route } from "react-router-dom";     /* Entorno Web */

import Login from "../view/Login/Login";
import Calendario from "../view/Agenda/Calendario";

import Usuarios from "../view/Usuario/AddUsuarioLoginPx";

import Pacientes from "../view/Paciente/Pacientes"
import AddPaciente from "../view/Paciente/AddPaciente";
import AddPacienteExpress from "../view/Paciente/AddPacienteExpress";
import EditPaciente from "../view/Paciente/EditPaciente";

import RolPaciente from "../view/Rol_Paciente/RolPaciente";
import EditRolPaciente from "../view/Rol_Paciente/EditRolPaciente";

import CanalizarPaciente from "../view/Canalizaciones/FormularioCanalizarPaciente"

import Psicologo from "../view/Psicologo/Psicologo";
import AddPsicologo from "../view/Psicologo/AddPsicologo";
import EditPsicologo from "../view/Psicologo/EditPsicologo";

import Expedientes from "../view/Expediente/Expedientes";

import Reporte from "../view/Reporte/Reporte";

import Colegas from "../view/Colegas/Colegas";
import AddColega from "../view/Colegas/AddColega";
import EditColega from "../view/Colegas/EditColega";

import Familiares from "../view/Familiar/Familiares";
import AddFamiliar from "../view/Familiar/AddFamiliar";
import EditFamiliar from "../view/Familiar/EditFamiliar";

import Recomendacion from "../view/Recomendaciones/Recomendacion";
import AddRecomendacion from "../view/Recomendaciones/AddRecomendacion";

import Sesiones from "../view/Sesiones/Sesiones";
import Canalizaciones from "../view/Canalizaciones/Canalizaciones";
import Citas from "../view/Citas/Citas";
import Notificaciones from "../view/Notificaciones/Notificaciones";
import Pendientes from "../view/Pendientes/Pendientes";
import Administracion from "../view/Administracion/Administracion";


function App() {

  // Entorno Local
  /*
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />}></Route>

        <Route exact path="/calendario" element={<Calendario />}></Route>

        <Route exact path="/AddUsuarioLoginPx" element={<Usuarios />}></Route>

        <Route exact path="/pacientes" element={<Pacientes />}></Route>
        <Route exact path="/addpaciente" element={<AddPaciente />}></Route>
        <Route exact path="/addpacienteexpress" element={<AddPacienteExpress />}></Route>
        <Route exact path="/pacientes/:id" element={<Pacientes />}></Route>
        <Route exact path="/editpaciente/:id" element={<EditPaciente />}></Route>

        <Route exact path="/psicologo/:id" element={<Psicologo />}></Route>
        <Route exact path="/addpsicologo" element={<AddPsicologo />}></Route>
        <Route exact path="/editpsicologo/:id" element={<EditPsicologo />}></Route>

        <Route exact path="/familiares" element={<Familiares />}></Route>
        <Route exact path="/familiares/:id" element={<Familiares />}></Route>
        <Route exact path="/addfamiliar" element={<AddFamiliar />}></Route>
        <Route exact path="/editfamiliar/:id" element={<EditFamiliar />}></Route>

        <Route exact path="/expedientes" element={<Expedientes />}></Route>
        <Route exact path="/expedientes/:id" element={<Expedientes />}></Route>

        <Route exact path="/reporte/:id" element={<Reporte />}></Route>

        <Route exact path="/sesiones" element={<Sesiones />}></Route>
        <Route exact path="/sesiones/:id" element={<Sesiones />}></Route>

        <Route exact path="/canalizaciones" element={<Canalizaciones />}></Route>
        <Route exact path="/FormularioCanalizarPaciente" element={<CanalizarPaciente />}></Route>
        <Route exact path="/canalizaciones/:id" element={<Canalizaciones />}></Route>

        <Route exact path="/colegas" element={<Colegas />}></Route>
        <Route exact path="/addcolega" element={<AddColega />}></Route>
        <Route exact path="/editcolega/:id" element={<EditColega />}></Route>

        <Route exact path="/citas" element={<Citas />}></Route>
        <Route exact path="/citas/:id" element={<Citas />}></Route>

        <Route exact path="/notificaciones" element={<Notificaciones />}></Route>

        <Route exact path="/pendientes" element={<Pendientes />}></Route>

        <Route exact path="/administracion" element={<Administracion />}></Route>

      </Routes>
    </BrowserRouter>
  );
  */


  // Entorno Web

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />}></Route>

        <Route exact path="/calendario" element={<Calendario />}></Route>

        <Route exact path="/AddUsuarioLoginPx" element={<Usuarios />}></Route>

        <Route exact path="/pacientes" element={<Pacientes />}></Route>
        <Route exact path="/addpaciente" element={<AddPaciente />}></Route>
        <Route exact path="/addpacienteexpress" element={<AddPacienteExpress />}></Route>
        <Route exact path="/pacientes/:id" element={<Pacientes />}></Route>
        <Route exact path="/editpaciente/:id" element={<EditPaciente />}></Route>

        <Route exact path="/rolpaciente/:id" element={<RolPaciente />}></Route>
        <Route exact path="/editrolpaciente/:id" element={<EditRolPaciente />}></Route>

        <Route exact path="/psicologo/:id" element={<Psicologo />}></Route>
        <Route exact path="/addpsicologo" element={<AddPsicologo />}></Route>
        <Route exact path="/editpsicologo/:id" element={<EditPsicologo />}></Route>

        <Route exact path="/familiares" element={<Familiares />}></Route>
        <Route exact path="/familiares/:id" element={<Familiares />}></Route>
        <Route exact path="/addfamiliar" element={<AddFamiliar />}></Route>
        <Route exact path="/editfamiliar/:id" element={<EditFamiliar />}></Route>

        <Route exact path="/expedientes" element={<Expedientes />}></Route>
        <Route exact path="/expedientes/:id" element={<Expedientes />}></Route>

        <Route exact path="/reporte/:id" element={<Reporte />}></Route>

        <Route exact path="/sesiones" element={<Sesiones />}></Route>
        <Route exact path="/sesiones/:id" element={<Sesiones />}></Route>

        <Route exact path="/canalizaciones" element={<Canalizaciones />}></Route>
        <Route exact path="/FormularioCanalizarPaciente" element={<CanalizarPaciente />}></Route>
        <Route exact path="/canalizaciones/:id" element={<Canalizaciones />}></Route>

        <Route exact path="/colegas" element={<Colegas />}></Route>
        <Route exact path="/addcolega" element={<AddColega />}></Route>
        <Route exact path="/editcolega/:id" element={<EditColega />}></Route>

        <Route exact path="/citas" element={<Citas />}></Route>
        <Route exact path="/citas/:id" element={<Citas />}></Route>

        <Route exact path="/notificaciones" element={<Notificaciones />}></Route>

        <Route exact path="/recomendaciones" element={<Recomendacion />}></Route>
        <Route exact path="/addRecomendaciones/:id" element={<AddRecomendacion />}></Route>

        <Route exact path="/pendientes" element={<Pendientes />}></Route>

        <Route exact path="/administracion" element={<Administracion />}></Route>

      </Routes>
    </Router>
  );

}

export default App;



