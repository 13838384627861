
import axios from "axios";


const FAMILIARES_API_BASE_URL = "/server/familiares";
const FAM_EDIT_API_BASE_URL = "/server/familiarEdit";
const FAM_ES_PAC_API_BASE_URL = "/server/vFamEsPac";
const FAM_NO_ES_PAC_API_BASE_URL = "/server/vFamNoEsPac";


// const FAM_ES_PAC_API_BASE_URL = "http://localhost:3002/vFamEsPac";
// const FAM_NO_ES_PAC_API_BASE_URL = "http://localhost:3002/vFamNoEsPac";
// const FAMILIARES_API_BASE_URL = "http://localhost:3002/familiares";
// const FAM_EDIT_API_BASE_URL = "http://localhost:3002/familiarEdit";


const Buffer = require('buffer/').Buffer;
const _ = require('lodash');        // _.isEmpty(familiar[i].f_fotografia.data  --- valida si un obj está vacío


class FamiliaresService {

    // obtener familiar por Id de table familiar
    async getFamiliarIndividual(id_familiar) {
        const response = await axios.get(`${FAMILIARES_API_BASE_URL}individual?id_familiar=${id_familiar}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliar familiaresindividual: ', error);
            });

        const familiar = response.data;

        //  convertir imagen blob a Uint8Array
        const archivoBase64 = Buffer.from(familiar[0].fotografia.data, 'binary');

        if (familiar[0].formatoFoto === "png") {
            // Uint8Array a String binario tipo PNG
            const base64String = `data:image/png;base64,${archivoBase64}`;
            // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
            const base64Data = base64String.split(',')[2];
            //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
            const imgBlob = `data:image/png;base64,${base64Data}`;
            familiar[0].fotografia.data = imgBlob;
        } else if (familiar[0].formatoFoto === "jpeg") {
            const base64String = `data:image/jpeg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
            const imgBlob = `data:image/jpeg;base64,${base64Data}`;
            familiar[0].fotografia.data = imgBlob;
        } else if (familiar[0].formatoFoto === "jpg") {
            const base64String = `data:image/jpg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];
            const imgBlob = `data:image/jpg;base64,${base64Data}`;
            familiar[0].fotografia.data = imgBlob;
        }

        return familiar;
    }


    // // obtener todos los familiares (no pac) de la Tabla Familiar
    async getFamiliares() {
        const response = await axios.get(FAMILIARES_API_BASE_URL)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliares: ', error);
            });
        const familiar = response.data;
        return familiar;
    }


    // get Tabla familiar Buscador
    async getFamiliarBuscador(palabra) {
        const response = await axios.get(`${FAMILIARES_API_BASE_URL}buscador?palabra=${palabra}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliaresBuscador: ', error);
            });
        const familiar = response.data;
        return familiar;
    }


    // obtener Familiares NO pacientes relacionados con un UN paciente por su ID
    async getFamiliarNoEsPaciente(fk_paciente) {
        const response = await axios.get(`${FAM_NO_ES_PAC_API_BASE_URL}?fk_paciente=${fk_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliarNoEsPaciente: ', error);
            });

        const familiar = response.data;

        if (familiar.length !== 0) {
            
            for (let i = 0; i < familiar.length; i++) {

                //  convertir imagen blob a Uint8Array
                const archivoBase64 = Buffer.from(familiar[i].f_fotografia.data, 'binary');

                if (familiar[i].f_formatoFoto === "png") {
                    // Uint8Array a String binario tipo PNG
                    const base64String = `data:image/png;base64,${archivoBase64}`;
                    // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
                    const base64Data = base64String.split(',')[2];
                    //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                } else if (familiar[i].f_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                } else if (familiar[i].f_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                }
            }
        }

        return familiar;
    }


    // obtener Familiares NO son pacientes por ID Familiar para:  ----   EditFamiliar
    async getEditFamNoEsPaciente(id_familiar) {
        const response = await axios.get(`${FAM_NO_ES_PAC_API_BASE_URL}Edit?id_familiar=${id_familiar}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getEditFamNoEsPaciente: ', error);
            });

        const familiar = response.data;

        if (familiar.length !== 0) {
            
            for (let i = 0; i < familiar.length; i++) {

                //  convertir imagen blob a Uint8Array
                const archivoBase64 = Buffer.from(familiar[i].f_fotografia.data, 'binary');

                if (familiar[i].f_formatoFoto === "png") {
                    // Uint8Array a String binario tipo PNG
                    const base64String = `data:image/png;base64,${archivoBase64}`;
                    // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
                    const base64Data = base64String.split(',')[2];
                    //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                } else if (familiar[i].f_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                } else if (familiar[i].f_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                }
            }
        }

        return familiar;
    }


    // obtener Familiares SON pacientes
    async getFamiliarEsPaciente(id_paciente) {
        const response = await axios.get(`${FAM_ES_PAC_API_BASE_URL}?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliarEsPaciente: ', error);
            });

        const familiar = response.data;

        if (familiar.length !== 0) {
            
            for (let i = 0; i < familiar.length; i++) {

                //  convertir imagen blob a Uint8Array
                const archivoBase64 = Buffer.from(familiar[i].pf_fotografia.data, 'binary');

                if (familiar[i].pf_formatoFoto === "png") {
                    // Uint8Array a String binario tipo PNG
                    const base64String = `data:image/png;base64,${archivoBase64}`;
                    // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
                    const base64Data = base64String.split(',')[2];
                    //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                } else if (familiar[i].pf_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                } else if (familiar[i].pf_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                }
            }
        }

        return familiar;
    }


    async createFamiliares(familiar) {
        const response = await axios.post(FAMILIARES_API_BASE_URL, familiar)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error('createFamiliares FS: ', error);
        });

        // console.log(response.status)

        const fam = response.data;

        return response;
    }


    async EditarFamiliar(familiar) {
        const response = await axios.put(FAM_EDIT_API_BASE_URL, familiar)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('EditarFamiliar FS: ', error);
            });
        const fam = response.data;
        return fam;
    }


    async getUltimoFamiliar() {
        const response = await axios.get(FAMILIARES_API_BASE_URL)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliares: ', error);
            });
        const familiar = response.data;
        const ultimoFamiliar = familiar.slice(-1)[0];
        return ultimoFamiliar;
    }

    // DELETE familiar, cuando se crea y al agregar parentesco éste se repite
    async DeleteFamiliar(id_familiar) {

        console.log("DeleteFamiliar(id_familiar) -----------")
        console.log(id_familiar)

        return await axios.delete(`${FAMILIARES_API_BASE_URL}delete?id_familiar=${id_familiar}`);
        
    }

}


export default new FamiliaresService();


