
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { parse, format } from 'date-fns';
import { useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";
import CitasService from "../../services/CitasService";
import PendientesService from "../../services/PendientesService";
import InformacionPacientes from "../../services/InformacionPacientes";
import RecomendacionService from "../../services/RecomendacionService";

import FormularioRegistroCita from "../Agenda/FormularioRegistroCita";
import FormularioRegistroCitaPX from "../Agenda/FormularioRegistroCitaPX";
import FormularioRegistroCitaPXActivo from "../Agenda/FormularioRegistroCitaPXActivo";
import FormularioEditarCita from "../Agenda/FormularioEditarCita";
import FormularioEditarCitaPX from "../Agenda/FormularioEditarCitaPX";

import "../../styles/Calendario.css";
import "../../styles/FormularioEditarCita.css";
import "../../styles/Pacientes.css";
import "../../styles/FullCalendar.css";

import img_edit from "../../img/edit_note.svg";
import SesionesService from "../../services/SesionesService";



const Calendario = () => {
    const [pacientes, setPacientes] = useState([]);     // recibe los pacientes del sistema
    const [psicologos, setPsicologos] = useState([]);
    const [paciente, setPaciente] = useState([]);   // info paciente individual
    const [psicologo, setPsicologo] = useState([]);     // info psicologo individual
    const [estatusPac, setEstatusPaciente] = useState("");
    const [eventos, setEventos] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [pendientes, setPendientes] = useState([]);
    const [recomendacion, setRecomendacion] = useState([]);
    const [fechaVal, setFechaValidacion] = useState("");   // regresa validación de fecha
    const [foto, setFoto] = useState('');  // bandera, hay o no foto en la DB
    const [inputValue, setInputValue] = useState("");
    const [fotografia, setFotografia] = useState('');

    const formatosFecha = ["DD-MM-YYYY", "DD/MM/YYYY"];
    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);  // usuario que ingresó al sistema
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    function refreshPage() {
        window.location.reload(false);
    }

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarErrorFecha(mensaje) {
        Swal.fire({
            title: '¡Error en la Fecha ingresada!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarErrorHora(mensaje) {
        Swal.fire({
            title: '¡Error en la Hora ingresada!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarErrorHraOcupado(mensaje) {
        Swal.fire({
            title: '¡Error: Hora ocupada!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    const getData = async () => {
        if (usuario.rol === "psi") {
            const data = await CitasService.getCitasPS(usuario.id_psicologo);
            setEventos(data.map(evento => ({
                title: (evento.p_nombre + " " + evento.p_ap1 + " " + evento.p_ap2),
                start: `${evento.fecha}T${evento.hora_i}:00`,
                end: `${evento.fecha}T${evento.hora_f}:00`,
                editable: true
            })));

            const data2 = await PacientesService.getPacientesCalendario();
            setPacientes(data2);

        } else if (usuario.rol === "pax") {
            const data = await CitasService.getCitasPacientePX(usuario.id_paciente);
            const data2 = await CitasService.getCitasPsicologoPX(usuario.psicologo, usuario.id_paciente);
            const eventosCombinados = [...data.map(evento => (
                {
                    title: (evento.p_nombre + " " + evento.p_ap1 + " " + evento.p_ap2),
                    start: `${evento.fecha}T${evento.hora_i}:00`,
                    end: `${evento.fecha}T${evento.hora_f}:00`,
                    editable: true,
                    description: 'Paciente'
                }
            )), ...data2.map(evento => (
                {
                    title: "Ocupado",
                    start: `${evento.fecha}T${evento.hora_i}:00`,
                    end: `${evento.fecha}T${evento.hora_f}:00`,
                    color: 'gray',
                    description: 'Psicologo'
                }
            ))]

            setEventos(eventosCombinados);

            const data3 = await PsicologoService.getPsicologos();
            setPsicologos(data3);
        }
    };


    const getDataPendientes = async () => {
        if (usuario.rol === "psi") {
            const data = await PendientesService.getPendientes(usuario.id_psicologo);
            setPendientes(data);
        }
    };

    const getDataRecomendaciones = async () => {
        if (usuario.rol === "pax") {
            const data = await RecomendacionService.getRecomendaciones(usuario.id_paciente)
            setRecomendacion(data);
        }
    };

    const getDataInfoPacientePsicologo = async () => {
        if (usuario.rol === "pax") {
            const data = await PacientesService.getInfoPaciente(usuario.id_paciente);   // info individual
            setPaciente(data[0]);

            const data2 = await PsicologoService.getPsicologoIndividual(usuario.psicologo);     // info individual, // usuario.psicologo, es el Id del psic que atiende, en rol: pax
            setPsicologo(data2[0]);
        }
    }

    useEffect(() => {
        getData();
        getDataPendientes();
        getDataRecomendaciones();
        getDataInfoPacientePsicologo();  // info pac de la sesión
    }, []);

    const cargarInformacion = async (id_paciente) => {
        const data = await PacientesService.getInfoPaciente(id_paciente);

        data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo);
        data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil);
        data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo);
        data[0].pais = InformacionPacientes.paisesPaciente(data[0].pais);

        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }

        setInformacion(data[0]);
        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInputValue(data[0].nombre + ' ' + data[0].ap1 + ' ' + data[0].ap2);
        setEstatusPaciente(data[0].estatus);

    }


    /*********************************************************/

    // Informacion MODAL PACIENTE AGENDADO,  sesión Psicólogo
    const informacionPaciente = async (info) => {

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fechaF, horaI, horaF);

        cargarInformacion(data[0].id_paciente);

        MySwal.fire({
            title: 'Paciente agendado',
            html: (
                <div className="datos-paciente-modal">
                    <table className="modal-pax-agendado">
                        <tbody>
                            <tr className="padding-row">
                                <td id="td-img-agendado">
                                    <div className="div-avatar-px">
                                        {
                                            foto === "1" ? (
                                                fotografia === "" ? (
                                                    <div id="img_avatar_px">
                                                        <label> Cargando imagen...</label>
                                                    </div>
                                                ) : (
                                                    <div id="img_avatar_px">
                                                        <img src={fotografia} alt="base64"></img>
                                                    </div>
                                                )
                                            ) : (<div></div>)
                                        }
                                    </div>
                                </td>
                                <td>
                                    { /*    entorno local
                                <a href={`/pacientes/${data[0].id_paciente}`}>
                                    <label id="lbl-px-agendado">{data[0].nombre} {data[0].ap1} {data[0].ap2}</label>
                                </a>
                                    */}

                                    {/* entorno web   */}
                                    <a href={`#/pacientes/${data[0].id_paciente}`}>
                                        <label id="lbl-px-agendado">{data[0].p_nombre} {data[0].p_ap1} {data[0].p_ap2}</label>
                                    </a>
                                </td>
                            </tr>
                            <tr className="padding-row">
                                <td><label className="lbl-fecha-agendado" htmlFor="lbl-fecha-agendado">Fecha:</label></td>
                                <td><label id="lbl-fecha-agendado">{data[0].fecha}</label></td>
                            </tr>
                            <tr className="padding-row">
                                <td><label className="lbl-horaI-agendado" htmlFor="lbl-horaI-agendado">Horario:</label></td>
                                <td><label id="lbl-horaI-agendado">{data[0].hora_i} - {data[0].hora_f}</label></td>
                            </tr>
                            <tr className="padding-row">
                                <td><label className="lbl-edad-agendado" htmlFor="lbl-edad-agendado">Edad:</label></td>
                                <td><label id="lbl-edad-agendado">{data[0].edad}</label></td>
                            </tr>
                            <tr className="padding-row">
                                <td><label className="lbl-trabajo-agendado" htmlFor="lbl-trabajo-agendado">Trabajo actual:</label></td>
                                <td><label id="lbl-trabajo-agendado">{data[0].trabajo_actual}</label></td>
                            </tr>
                            <tr className="padding-row">
                                <td><label className="lbl-telefono-agendado" htmlFor="lbl-telefono-agendado">Telefono:</label></td>
                                <td><label id="lbl-telefono-agendado">{data[0].tel}</label></td>
                            </tr>
                            <tr className="padding-row">
                                <td><label className="lbl-ciudad-agendado" htmlFor="lbl-ciudad-agendado">Ciudad:</label></td>
                                <td><label id="lbl-ciudad-agendado">{data[0].ciudad}</label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ),
            showCancelButton: true,
            showDenyButton: true,
            showCloseButton: true,
            confirmButtonText: 'Editar cita',
            cancelButtonText: 'Ir a sesión',
            denyButtonText: 'Cancelar cita',
        }).then((result) => {
            if (result.isConfirmed) {
                editarCita(info);
            } else if (result.isDenied) {
                eliminarCita(info);
            } else if (result.dismiss === 'cancel') {
                // navigate(`/sesiones/${data[0].id_paciente}`);       // entorno local
                navigate(`/sesiones/${data[0].id_paciente}`);       // entorno web
            }
        });
    }



    /**************  Agendar cita por Psicologo  ***************/

    // Componente MODAL para agendar cita por el psicolgo
    const agendarCita = async (info) => {

        const fechaF = moment(info.date).format('YYYY-MM-DD');      // fecha de la agenda
        const horaI = moment(info.date).format('HH:mm');
        const horaF = sumarMediaHora(info.date);
        const horaFForm = moment(horaF).format('HH:mm');
        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioRegistroCita pacientes={pacientes} fechaF={fechaF} horaI={horaI} horaF={horaFForm} />
        );

        const horaValida = compararHora(info.date);
        const fechaValida = compararFecha(info.date);

        if (fechaValida === "fechaValida") {
            if (horaValida === "horaValida") {
                Swal.fire({
                    title: "Agendar cita",
                    html: formularioHtml,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Guardar",
                    cancelButtonText: "Nuevo paciente",
                    preConfirm: () => {
                        const paciente = document.getElementById("select-paciente").value;
                        const fecha = moment(document.getElementById("input-fecha").value).format('DD-MM-YYYY');
                        const horaI = document.getElementById("input-horaI").value;
                        const horaF = document.getElementById("input-horaF").value;
                        const modalidad = document.getElementById("select-modalidad").value;
                        const comentario = document.getElementById("input-comentario").value;
                        return { paciente, fecha, horaI, horaF, comentario, modalidad };
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        const datos = result.value;
                        guardarDatos(datos);
                    } else if (result.dismiss === 'cancel') {
                        //navigate('/addpacienteexpress');        // entorno local
                        navigate('/addpacienteexpress');        // entorno web
                    }
                });
            }
        }
    };

    //Psicólogo, agendar cita
    const guardarDatos = async (datos) => {

        if (datos.paciente === "Selecciona una opción") {

            mostrarError("Asegúrate de seleccionar un paciente");

        } else {

            if (validarFecha(datos.fecha)) {

                if (validarHora(datos.horaI) && validarHora(datos.horaF)) {

                    const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                    const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.id_psicologo);

                    if (solapamiento === true) {    // está desocupado este horario
                        let cita = {
                            fecha: fechaFormateada,
                            hora_i: datos.horaI,
                            hora_f: datos.horaF,
                            modalidad: datos.modalidad,
                            estatus_cita: "Confirmada",
                            comentario: datos.comentario,
                            fk_paciente: parseInt(datos.paciente),
                            fk_psicologo: usuario.id_psicologo
                        }
                        try {

                            // Crear la cita
                            const respuestaCita = await CitasService.crearCita(cita);

                            // no funciona, obtiene la penúltima
                            // const ultimaCita = await CitasService.getUltimaCita(usuario.id_psicologo);  // obtener la última cita

                            let sesion = {
                                sesion: "",
                                estatus_sesion: "",
                                fk_cita_sesion: respuestaCita.data.insertId
                            }

                            // Crear la sesión
                            const respuestaSesion = await SesionesService.crearSesion(sesion);

                            if (respuestaCita.status === 200 && respuestaSesion.status === 200) {

                                Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                    if (result.isConfirmed) {
                                        refreshPage();
                                    }
                                }).then(function () {
                                    refreshPage();
                                });
                            } else {
                                mostrarError("Hubo un problema al registrar la cita");
                            }
                        } catch (error) {
                            mostrarError("Hubo un problema al registrar la cita");
                            console.log('Error al realizar la inserción:', error.message);
                        }

                    } else {
                        mostrarError("Ya hay una cita en ese horario");
                    }

                } else {
                    mostrarError("Hora incorrecta, asegurate que el formato sea HH:MM");
                }

            } else {
                mostrarError("¡Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
            }
        }
    };

    const editarCita = async (info) => {

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fecha = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fecha, horaI, horaF);

        const fechaF = moment(info.event.start).format('YYYY-MM-DD');

        let pac;

        for (let i = 0; i < pacientes.length; i++) {
            if (pacientes[i].id_paciente === data[0].id_paciente) {
                pac = pacientes[i]
            }
        }

        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioEditarCita paciente={pac} fechaF={fechaF} horaI={horaI} horaF={horaF} modalidad={data[0].modalidad} comentario={data[0].comentario} />
        );
        Swal.fire({
            title: "Editar cita",
            html: formularioHtml,
            // showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: "Guardar cambios",
            // cancelButtonText: "Cancelar",
            preConfirm: () => {
                const paciente = document.getElementById("select-px-editar").value;
                const fecha = moment(document.getElementById("input-fecha-editar").value).format('DD-MM-YYYY');
                const horaI = document.getElementById("input-horaI-editar").value;
                const horaF = document.getElementById("input-horaF-editar").value;
                const modalidad = document.getElementById("select-modalidad-editar").value;
                const estatus_cita = "Modificada";
                const comentario = document.getElementById("input-comentario-editar").value;
                /*const estatus = document.getElementById("select-estatus").value;*/
                return { paciente, fecha, horaI, horaF, modalidad, estatus_cita, comentario };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                editarDatos(datos, data[0].id_cita, data[0].estatus_cita);
            }
        });
    };

    const editarDatos = async (datos, idCita, estatus) => {

        if (datos.paciente === "Selecciona una opción") {

            mostrarError("Asegúrate de seleccionar un paciente.");

        } else {
            if (validarFecha(datos.fecha)) {
                if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                    if (datos.horaI >= datos.horaF) {
                        mostrarErrorHora("La hora inicial no es menor a la final");
                    } else {
                        const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                        const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.id_psicologo);
                        if (solapamiento === true) {
                            let cita = {
                                fecha: fechaFormateada,
                                hora_i: datos.horaI,
                                hora_f: datos.horaF,
                                modalidad: datos.modalidad,
                                estatus_cita: datos.estatus_cita,
                                comentario: datos.comentario,
                                id_paciente: parseInt(datos.paciente),
                                id_psicologo: usuario.id_psicologo,
                                id_cita: idCita
                            }
                            try {
                                const respuesta = await CitasService.editarCita(cita);

                                if (respuesta.status === 200) {
                                    Toast.fire("Se editó la cita correctamente!", "", "success").then((result) => {
                                        if (result.isConfirmed) {
                                            refreshPage();
                                        }
                                    }).then(function () {
                                        refreshPage();
                                    });
                                } else {
                                    mostrarError("Hubo un problema al registrar la cita.");
                                    console.log("Estatus-200...");
                                }
                            } catch (error) {
                                mostrarError("Hubo un problema al registrar la cita");
                                console.log('Error al realizar la inserción:', error.message);
                            }
                        } else {
                            mostrarErrorHraOcupado("Ya hay una cita en ese horario.");
                        }
                    }
                } else {
                    mostrarError("Hora incorrecta, asegurate que el formato sea HH:MM");
                }
            } else {
                mostrarError("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
            }
        }
    };

    const eliminarCita = async (info) => {

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fechaF, horaI, horaF);

        Swal.fire({
            title: "Eliminar cita",
            text: "¿Esta seguro de eliminar la cita?",
            showCancelButton: true,
            showConfirmButton: false,
            showCloseButton: true,
            showDenyButton: true,
            denyButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isDenied) {
                eliminarDatos(data[0].id_cita);
            }
        });
    };

    const eliminarDatos = async (idCita) => {
        const respuestaSesion = await SesionesService.eliminarSesion(idCita);
        const respuestaCita = await CitasService.eliminarCita(idCita);
        if (respuestaCita.status === 200 && respuestaSesion.status === 200) {
            Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    refreshPage();
                }
            }).then(function () {
                refreshPage();
            });
        } else {
            mostrarError("Hubo un problema al eliminar la cita.");
        }
    };

    /*********************************************************/

    const verficarSolapamiento = async (fecha, horaI, horaF, id_psicologo) => {
        const data = await CitasService.comprobarCita(fecha, horaI, horaF, id_psicologo);
        if (data[0] === undefined) {    // Sí se puede agendar, está vacía
            return true;
        } else {    // No se puede agendar, ya está ocupada
            return false;
        }
    }

    function verificarCitaAnterior(fechaAnterior, fecha) {

        const lastAppointmentDate = new Date(fechaAnterior); // Fecha de la última cita
        const currentDate = new Date(fecha); // Fecha actual
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Un día en milisegundos
        const daysDifference = Math.floor((currentDate - lastAppointmentDate) / oneDayInMilliseconds);

        if (daysDifference >= 7) {
            return true;
        } else {
            return false;
        }
    }

    function verficarTiempo(horaI, horaF) {
        const tInicio = new Date();
        const pInicio = horaI.split(':');
        tInicio.setHours(parseInt(pInicio[0]), parseInt(pInicio[1]));

        const tFin = new Date();
        const pFin = horaF.split(':');
        tFin.setHours(parseInt(pFin[0]), parseInt(pFin[1]));

        const diferenciaEnMilisegundos = Math.abs(tFin - tInicio);
        const minutosDiferencia = Math.floor(diferenciaEnMilisegundos / (1000 * 60));
        return minutosDiferencia;
    }

    function validarFecha(fecha) {
        return moment(fecha, formatosFecha, true).isValid();
    }

    function validarHora(hora) {
        return moment(hora, "HH:mm", true).isValid();
    }

    function sumarMediaHora(hora) {
        const horaActual = new Date(hora); // Crea un objeto Date a partir de la hora recibida
        horaActual.setMinutes(horaActual.getMinutes() + 60); // Suma 60 minutos
        return horaActual;
    }

    const compararFecha = (fechaAgenda) => {

        const hoy = new Date();     // fecha actual de la Pc
        var anioHoy = hoy.getFullYear();    // Returns the year
        var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)

        // fecha obtenida en campo input
        var anioFecha = fechaAgenda.getFullYear();    // Returns the year
        var mesFecha = fechaAgenda.getMonth() + 1;  // Returns the month (from 0-11)
        var diaFecha = fechaAgenda.getDate();     // Returns the day of the month (from 1-31)

        if (anioFecha < anioHoy) {
            mostrarErrorFecha("El Año seleccionado es anterior al actual.");
        } else if (anioFecha == anioHoy && mesFecha < mesHoy) {
            mostrarErrorFecha("El Mes seleccionado es anterior al actual");
        } else if (anioFecha == anioHoy && mesFecha == mesHoy && diaFecha < diaHoy) {
            mostrarErrorFecha("El Día seleccionado es anterior a Hoy");
        } else if (anioFecha == anioHoy && mesFecha >= mesHoy) {
            return "fechaValida";
        } if (anioFecha > anioHoy) {
            return "fechaValida";
        }

        // si No es válida la fecha, regresará: undefined
    }

    const compararHora = (horaInput) => {

        let horaActual = new Date();

        if (horaInput.getTime() > horaActual.getTime()) {    // .getTime(), convierte a milisegundos desde 1970
            return "horaValida"
        } else {
            mostrarErrorHora("La hora seleccionada es menor a la actual.");
            // si no es valida la hora, retorna: undefined
        }
    }

    /*********************************************************/



    // Informacion MODAL PACIENTE agendado: rol Paciente
    const informacionPacientePX = async (info) => {

        if (info.event.extendedProps.description === 'Paciente') {
            const horaI = moment(info.event.start).format('HH:mm');
            const horaF = moment(info.event.end).format('HH:mm');
            const fechaF = moment(info.event.start).format('YYYY-MM-DD');

            const data = await CitasService.getInfoCita(fechaF, horaI, horaF);

            MySwal.fire({
                title: 'Cita agendada',
                html: (
                    <div className="datos-paciente-modal">
                        <div>
                            <div className="div-citaAgendada-pac">
                                <div className="div-avatar-pac">
                                    <div id="img_avatar_px">
                                        <img src={data[0].fotografia.data} alt="base64"></img>
                                    </div>
                                </div>
                                <div className="div-fotoPac-pac">
                                    <div>
                                        <label className="label-paciente" htmlFor="lbl-paciente"> Paciente: </label>
                                    </div>
                                    <div>
                                        <label id="lbl-paciente">{data[0].p_nombre} {data[0].p_ap1} {data[0].p_ap2}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="modal-pax-agendado">
                            <tbody>
                                <tr className="padding-row">
                                    <td>
                                        <label className="lbl-fecha-agendado" htmlFor="lbl-fecha-agendado">Fecha:</label>
                                    </td>
                                    <td>
                                        <input type="date" id="lbl-fecha-agendado" value={fechaF} disabled />
                                    </td>
                                </tr>
                                <tr className="padding-row">
                                    <td>
                                        <label className="lbl-horaI-agendado" htmlFor="lbl-horaI-agendado">Hora inicio:</label>
                                    </td>
                                    <td>
                                        <input type="time" id="lbl-horaI-agendado" value={horaI} disabled />
                                    </td>
                                </tr>
                                <tr className="padding-row">
                                    <td>
                                        <label className="lbl-horaF-agendado" htmlFor="lbl-horaF-agendado">Hora final:</label>
                                    </td>
                                    <td>
                                        <input type="time" id="lbl-horaF-agendado" value={horaF} disabled />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>
                                        <hr></hr>
                                    </td>
                                    <td>
                                        <label className="lbl-psic-agendado" htmlFor="lbl-psic-agendado">Atenderá el proceso:</label>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>
                                        <label className="lbl-psic-agendado" htmlFor="lbl-psic-agendado">Psicólogo:</label>
                                    </td>
                                    <td>
                                        <label id="lbl-psic-agendado">{data[0].psi_nombre} {data[0].psi_ap1} {data[0].psi_ap2}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className="lbl-psic-agendado" htmlFor="lbl-psic-agendado">Cédula:</label>
                                    </td>
                                    <td>
                                        <label id="lbl-psic-agendado">{data[0].cedula}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className="lbl-psic-agendado" htmlFor="lbl-psic-agendado">Especialidad:</label>
                                    </td>
                                    <td>
                                        <label id="lbl-psic-agendado">{data[0].especialidad}</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ),
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: 'Editar cita',
                cancelButtonText: 'Salir',
                denyButtonText: 'Eliminar cita',
            }).then((result) => {
                if (result.isConfirmed) {
                    editarCitaPX(info);
                } else if (result.isDenied) {
                    // Lógica a ejecutar cuando se presiona: Eliminar cita
                    eliminarCitaPX(info);
                }
            });
        }
    }

    // administrar Cita por el Paciente
    const agendarCitaPX = async (info) => {

        const data = await PacientesService.getInfoPaciente(usuario.id_paciente);
        const data2 = await PsicologoService.getPsicologoIndividual(data[0].fk_psicologo);
        const psic = data2[0].nombre + " " + data2[0].ap1 + " " + data2[0].ap2;
        const fechaF = moment(info.date).format('YYYY-MM-DD');
        const horaI = moment(info.date).format('HH:mm');
        const horaF = sumarMediaHora(info.date);
        const horaFForm = moment(horaF).format('HH:mm');
        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioRegistroCitaPX psicologos={psicologos} fechaF={fechaF} horaI={horaI} horaF={horaFForm} />
        );

        const horaValida = compararHora(info.date);
        const fechaValida = compararFecha(info.date);

        if (fechaValida === "fechaValida") {
            if (horaValida === "horaValida") {
                Swal.fire({
                    title: "Agendar cita",
                    html: formularioHtml,
                    showCancelButton: true,
                    confirmButtonText: "Guardar",
                    cancelButtonText: "Cancelar",
                    preConfirm: () => {
                        const idPsicologo = document.getElementById("select-psicologo").value;    // retorna Id
                        const fecha = moment(document.getElementById("input-fecha").value).format('DD-MM-YYYY');
                        const horaI = document.getElementById("input-horaI").value;
                        const horaF = document.getElementById("input-horaF").value;
                        const modalidad = document.getElementById("select-modalidad").value;
                        const comentario = document.getElementById("input-comentario").value;

                        return { idPsicologo, fecha, horaI, horaF, comentario, modalidad };
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        const datos = result.value;
                        guardarDatosPX(datos);
                    }
                });
            }
        }
    }

    //Paciente, agendar cita
    const guardarDatosPX = async (datos) => {

        if (paciente.estatus === "A") {

            if (datos.idPsicologo === "Selecciona una opción") {

                mostrarError("Asegúrate de seleccionar un psicólogo.");

            } else {

                if (parseInt(usuario.psicologo) === parseInt(datos.idPsicologo)) {     // usuario.psicologo, es el Id del psic que atiende

                    if (validarFecha(datos.fecha)) {

                        if (validarHora(datos.horaI) && validarHora(datos.horaF)) {

                            const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                            const data = await CitasService.verificarCitaAnterior(usuario.id_paciente);
                            const citaAnt = verificarCitaAnterior(data[0].fecha, fechaFormateada);

                            if (citaAnt) {

                                const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                                if (tiempo < 60 || tiempo > 60) {

                                    mostrarErrorHora("La cita tiene que durar una hora.");

                                } else if (tiempo === 60) {

                                    const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                                    if (solapamiento === true) {
                                        let cita = {
                                            fecha: fechaFormateada,
                                            hora_i: datos.horaI,
                                            hora_f: datos.horaF,
                                            modalidad: datos.modalidad,
                                            estatus_cita: "Confirmada",
                                            comentario: datos.comentario,
                                            fk_paciente: usuario.id_paciente,
                                            fk_psicologo: parseInt(datos.idPsicologo)
                                        }
                                        try {

                                            // Crear la cita
                                            const respuestaCita = await CitasService.crearCita(cita);

                                            // respuestaCita.data.insertId, regresa el Id de la cita creada, que debe ser la última

                                            // no funciona
                                            // const ultimaCita = await CitasService.getUltimaCita(datos.idPsicologo);     // obtener la última cita

                                            let sesion = {
                                                sesion: "",
                                                estatus_sesion: "",
                                                fk_cita_sesion: respuestaCita.data.insertId
                                            }

                                            // Crear la sesión
                                            const respuestaSesion = await SesionesService.crearSesion(sesion);

                                            if (respuestaCita.status === 200 && respuestaSesion.status === 200) {

                                                Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                                    if (result.isConfirmed) {
                                                        refreshPage();
                                                    }
                                                }).then(function () {
                                                    refreshPage();
                                                });

                                            } else {
                                                mostrarError("Hubo un problema al registrar la cita");
                                                console.log("Estatus = 200");
                                            }

                                        } catch (error) {
                                            mostrarError("Hubo un problema al registrar la cita");
                                            console.log('Error al realizar la inserción:', error.message);
                                        }

                                    } else {
                                        mostrarErrorHraOcupado("Ya hay una cita en ese horario");
                                    }
                                }
                            } else {
                                mostrarError("Para agendar una cita, tiene que pasar al menos una semana de la cita anterior");
                            }

                        } else {
                            mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                        }

                    } else {
                        mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
                    }
                } else {
                    mostrarError("No puedes cambiar de psicólogo miestras tienes un proceso abierto.");
                }
            }

        } else if (paciente.estatus === "I") {

            if (datos.idPsicologo === "Selecciona una opción") {

                mostrarError("Asegúrate de seleccionar un psicólogo.");

            } else if (validarFecha(datos.fecha)) {

                if (validarHora(datos.horaI) && validarHora(datos.horaF)) {

                    const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                    const data = await CitasService.verificarCitaAnterior(usuario.id_paciente);
                    const citaAnt = verificarCitaAnterior(data[0].fecha, fechaFormateada);

                    if (citaAnt) {

                        const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                        if (tiempo < 60 || tiempo > 60) {

                            mostrarErrorHora("La cita tiene que durar una hora.");

                        } else if (tiempo === 60) {

                            const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                            if (solapamiento === true) {
                                let cita = {
                                    fecha: fechaFormateada,
                                    hora_i: datos.horaI,
                                    hora_f: datos.horaF,
                                    modalidad: datos.modalidad,
                                    estatus_cita: "Confirmada",
                                    comentario: datos.comentario,
                                    fk_paciente: usuario.id_paciente,
                                    fk_psicologo: parseInt(datos.idPsicologo)
                                }
                                try {

                                    // Crear la cita
                                    const respuestaCita = await CitasService.crearCita(cita);
                                    // respuestaCita.data.insertId, regresa el Id de la cita creada, que debe ser la última

                                    // no funciona
                                    // const ultimaCita = await CitasService.getUltimaCita(datos.idPsicologo);     // obtener la última cita

                                    let sesion = {
                                        sesion: "",
                                        estatus_sesion: "",
                                        fk_cita_sesion: respuestaCita.data.insertId
                                    }

                                    // Crear la sesión
                                    const respuestaSesion = await SesionesService.crearSesion(sesion);

                                    if (respuestaCita.status === 200 && respuestaSesion.status === 200) {

                                        Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                            if (result.isConfirmed) {
                                                refreshPage();
                                            }
                                        }).then(function () {
                                            refreshPage();
                                        });

                                    } else {
                                        mostrarError("Hubo un problema al registrar la cita");
                                        console.log("Estatus = 200");
                                    }

                                } catch (error) {
                                    mostrarError("Hubo un problema al registrar la cita");
                                    console.log('Error al realizar la inserción:', error.message);
                                }

                            } else {
                                mostrarErrorHraOcupado("Ya hay una cita en ese horario");
                            }
                        }
                    } else {
                        mostrarError("Para agendar una cita, tiene que pasar al menos una semana de la cita anterior");
                    }

                } else {
                    mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                }

            } else {
                mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
            }
        }
    }

    const editarCitaPX = async (info) => {

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fecha = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fecha, horaI, horaF);

        let psi;    // guardar datos del psicólogo

        for (let i = 0; i < psicologos.length; i++) {
            if (psicologos[i].id_psicologo === data[0].id_psicologo) {
                psi = psicologos[i]
            }
        }

        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioEditarCitaPX psicologo={psi} fechaF={fecha} horaI={horaI} horaF={horaF} modalidad={data[0].modalidad} comentario={data[0].comentario} />
        );

        Swal.fire({
            title: "Editar cita",
            html: formularioHtml,
            showCancelButton: true,
            confirmButtonText: "Guardar cambios",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const psicologo = document.getElementById("select-psicologo").value;
                const fecha = moment(document.getElementById("input-fecha").value).format('DD-MM-YYYY');
                const horaI = document.getElementById("input-horaI").value;
                const horaF = document.getElementById("input-horaF").value;
                const modalidad = document.getElementById("select-modalidad").value;
                const comentario = document.getElementById("input-comentario").value;
                const estatus_cita = "Modificada";
                return { psicologo, fecha, horaI, horaF, modalidad, comentario, estatus_cita };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                editarDatosPX(datos, data[0].id_cita);
            }
        });
    }

    const editarDatosPX = async (datos, idCita) => {

        if (validarFecha(datos.fecha)) {

            if (validarHora(datos.horaI) && validarHora(datos.horaF)) {

                if (datos.horaI >= datos.horaF) {

                    mostrarErrorHora("La hora Inicio debe ser menor que la hora Final.");

                } else {

                    const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                    const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                    if (tiempo < 60 || tiempo > 60) {

                        mostrarError("La cita tiene que durar una hora.");

                    } else if (tiempo === 60) {

                        const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                        if (solapamiento === true) {
                            let cita = {
                                fecha: fechaFormateada,
                                hora_i: datos.horaI,
                                hora_f: datos.horaF,
                                modalidad: datos.modalidad,
                                estatus_cita: datos.estatus_cita,
                                comentario: datos.comentario,
                                id_paciente: usuario.id_paciente,
                                id_psicologo: parseInt(datos.psicologo),
                                id_cita: idCita
                            }

                            try {

                                const respuesta = await CitasService.editarCita(cita, idCita);
                                console.log(respuesta.status)

                                if (respuesta.status === 200) {
                                    Toast.fire("Se editó la cita correctamente!", "", "success").then((result) => {
                                        if (result.isConfirmed) {
                                            refreshPage();
                                        }
                                    });
                                } else {
                                    mostrarError("Hubo un problema al registrar la cita.");
                                    console.log("Estatus 200")
                                }
                            } catch (error) {
                                mostrarError("Hubo un problema al registrar la cita");
                                console.log('Error al realizar la inserción:', error.message);
                            }
                        } else {
                            mostrarErrorHraOcupado("Ya hay una cita en ese horario.");
                        }
                    }
                }
            } else {
                mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
            }
        } else {
            mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
        }
    }

    const eliminarCitaPX = async (info) => {

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fechaF, horaI, horaF);

        Swal.fire({
            title: "Eliminar cita",
            text: "¿Esta seguro de eliminar la cita?",
            showCancelButton: true,
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isDenied) {
                eliminarDatosPX(data[0].id_cita);
            }
        });
    }

    const eliminarDatosPX = async (idCita) => {
        const respuestaSesion = await SesionesService.eliminarSesion(idCita);
        const respuestaCita = await CitasService.eliminarCita(idCita);
        if (respuestaCita.status === 200 && respuestaSesion.status === 200) {
            Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    refreshPage();
                }
            }).then(function () {
                refreshPage();
            });
        } else {
            mostrarError("Hubo un problema al eliminar la cita.");
        }
    }



    const handleComentario = (index, comentario) => {
        const pendienteComentario = pendientes[index]
        pendienteComentario.comentario = comentario;
    }


    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }



    return (
        <Layout>
            <Content>
                <div className="vista-calendario">
                    <div className="encabezado-usuario">
                        <div className="px_sesion_fam">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        {
                            usuario.rol === "pax" ? (
                                <div className="px_sesion_fam">
                                    <label id="lbl-enSesion"> Psicólogo que atiende: </label>
                                    <label>{psicologo.nombre} {psicologo.ap1} {psicologo.ap2}</label>
                                </div>
                            ) : (<></>)
                        }
                    </div>
                    {usuario.rol === "psi" ? (
                        <div className="calendario-datos">
                            <div className="calend">
                                {eventos !== undefined ? (
                                    <FullCalendar
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'listDay, dayGridMonth,timeGridWeek,multiMonthYear' // Agrega las vistas aquí
                                        }}
                                        buttonText={{
                                            day: 'Dia',
                                            today: 'Hoy', // Cambia el texto del botón "Hoy"
                                            month: 'Mes', // Cambia el texto del botón "Mes"
                                            week: 'Semana', // Cambia el texto del botón "Semana"
                                            year: 'Año' // Cambia el texto del botón "Día"
                                        }}
                                        locales={[esLocale]}
                                        slotLabelFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hourCycle: "h12",
                                            meridiem: 'short',
                                        }}
                                        eventTimeFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hourCycle: "h12",
                                        }}
                                        nowIndicator={true}
                                        slotMinTime="07:00:00"
                                        slotMaxTime="22:00:00"
                                        allDaySlot={false}
                                        themeSystem="pulse"
                                        plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                                        initialView="timeGridWeek"
                                        events={eventos}
                                        dateClick={agendarCita}
                                        eventClick={informacionPaciente}
                                    />
                                ) : (
                                    <p>Cargando eventos... </p>
                                )}
                            </div>
                            <div className="datos-paciente">
                                <div className="edit_delete-pendientes">
                                    <div id="px_h3_calend">
                                        <label id="lbl_datos_calend">Pendientes</label>
                                    </div>
                                </div>
                                <div className="div-pendientes-textArea">
                                    <div>
                                        {
                                            pendientes.length === 0 ? (
                                                <div className="div-pendientes-edit-delete">
                                                    <div className="div-pendientes-toolTip">
                                                        <label>No existen pendientes añadidos</label>
                                                    </div>
                                                </div>
                                            ) : (
                                                pendientes.map((pendiente, index) => (
                                                    <div>
                                                        <div className="div-pendientes-edit-delete">
                                                            <div key={index} id="id-hora-fecha-pendientes">
                                                                <label> Registrado el día: {pendiente.fecha} </label>
                                                            </div>
                                                            <div className="div-pendientes-toolTip">
                                                                <div id="container-toolTip-save">
                                                                    <span className="lbl-toolTip-save">
                                                                        Editar
                                                                    </span>
                                                                    { /*   entorno local
                                                                        <a href="/pendientes">
                                                                        <img src={img_edit} alt="edit" id="img_edit-calendar" />
                                                                    </a>
                                                                    */}

                                                                    {/*  entorno web   */}
                                                                    <a href="#/pendientes">
                                                                        <img src={img_edit} alt="edit" id="img_edit-calendar" />
                                                                    </a>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div-notif-px">
                                                            <p style={{ border: "none" }}>
                                                                <textarea id="textArea-pendientes" disabled rows="4" cols="50" onChange={(event) => handleComentario(index, event.target.value)} defaultValue={pendiente.comentario}></textarea>
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {usuario.rol === "pax" ? (
                                <div className="calendario-datos">
                                    <div className="calend">
                                        {eventos !== undefined ? (
                                            <FullCalendar
                                                headerToolbar={{
                                                    left: 'prev,next today',
                                                    center: 'title',
                                                    right: 'listDay, dayGridMonth,timeGridWeek,multiMonthYear' // Agrega las vistas aquí
                                                }}
                                                buttonText={{
                                                    day: 'Dia',
                                                    today: 'Hoy', // Cambia el texto del botón "Hoy"
                                                    month: 'Mes', // Cambia el texto del botón "Mes"
                                                    week: 'Semana', // Cambia el texto del botón "Semana"
                                                    year: 'Año' // Cambia el texto del botón "Día"
                                                }}
                                                locales={[esLocale]}
                                                slotLabelFormat={{
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hourCycle: "h12",
                                                    meridiem: 'short',
                                                }}
                                                eventTimeFormat={{
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hourCycle: "h12",
                                                }}
                                                nowIndicator={true}
                                                slotMinTime="07:00:00"
                                                slotMaxTime="22:00:00"
                                                allDaySlot={false}
                                                themeSystem="pulse"
                                                plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                                                initialView="timeGridWeek"
                                                events={eventos}
                                                dateClick={agendarCitaPX}
                                                eventClick={informacionPacientePX}
                                            />
                                        ) : (
                                            <p>Cargando eventos... </p>
                                        )}
                                    </div>
                                    <div className="datos-paciente">
                                        <div className="edit_delete-pendientes">
                                            <div id="px_h3_pax">
                                                <label>Recomendaciones</label>
                                            </div>
                                        </div>
                                        <div className="div-pendientes-textArea">
                                            <div>
                                                {
                                                    recomendacion.length === 0 ? (
                                                        <div className="div-pendientes-edit-delete">
                                                            <div className="div-pendientes-toolTip">
                                                                <label>No existen recomendaciones añadidas</label>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        recomendacion.map((recomendacion, index) => (
                                                            <div className="div-pendientes-box">
                                                                <div className="div-pendientes-edit-delete">
                                                                    <div key={index} id="id-hora-fecha-pendientes">
                                                                        <label> Registrada el día: {moment(recomendacion.fecha).format('DD-MM-YYYY')} </label>
                                                                    </div>
                                                                </div>
                                                                <div className="div-notif-px">
                                                                    <p style={{ border: "none" }}>
                                                                        <textarea id="textArea-pendientes" disabled rows="4" cols="50" defaultValue={recomendacion.recomendacion}></textarea>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {usuario.rol === 'adm' ? (
                                        <div className="calendario-datos">

                                            <h1>Agenda...</h1>

                                            <div className="datos-paciente">
                                                <div className="edit_delete-pendientes">
                                                    <div id="px_h3">
                                                        <h3>Pendientes del sistema</h3>
                                                    </div>
                                                </div>
                                                <div className="div-pendientes-textArea">

                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Content>
        </Layout >
    );
};

export default Calendario;
