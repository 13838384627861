import React from "react";
import { useNavigate } from "react-router-dom";

import "../styles/header.css";
import "../styles/MenuUsuario.css";
import logo from "../img/psiquedata_logo_trans.png";
import img_avatar from "../img/avatar.png";
import img_calendar from "../img/calendar_month_black.svg";
import img_salir from "../img/logout_black.svg";


const HeaderPx = () => {

  const usuarioString = sessionStorage.getItem('usuario');
  const usuario = JSON.parse(usuarioString);

  const navigate = useNavigate();

  const cerrarSesion = () => {
    navigate("/");
  };

  const handleAgenda = () => {
    navigate("/calendario");
  }


  return (
    <div className="div-header">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
      <button className="btn btn-primary" type="button" onClick={handleAgenda}>
          <img id="img_calendar" src={img_calendar} alt="img_calendar"></img>
          Agenda
        </button>
        <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          Cuenta
        </button>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item" href={`#/rolPaciente/${usuario.id_paciente}`}>
            <img id="img_profile" src={img_avatar} alt="img_profile"></img>
            {usuario.nombre} {usuario.ap1} {usuario.ap2}
          </a>
          </li>
        </ul>
        <button className="btn btn-primary" type="button" onClick={cerrarSesion}>
          <img id="img_salir" src={img_salir} alt="img_salir"></img>
          Salir
        </button>
      </div>
    </div>
  );
};

export default HeaderPx;